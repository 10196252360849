.not-found-wrapper {
   height: 100vh;
   display: flex;
   background-color: #141623;

   .error-placeholder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      margin-top: -100px;

      & > p:nth-child(2) {
         margin-top: 24px;
         margin-bottom: 8px;
         font-weight: 500;
         font-size: 24px;
         line-height: 34px;
         letter-spacing: -0.5px;
         color: white;
      }

      & > p:nth-child(3) {
         text-align: center;
         max-width:  310px;
         letter-spacing: 0.15px;
         color: rgba(255, 255, 255, 0.7);
         line-height: 24px;

         span {
            color: #17EDC3;
            cursor: pointer;
         }
      }
   }

   .loader {
      display: block;
      margin: auto;
   }
}

[theme-mode='light'] .not-found-wrapper {
   background-color: white;

   .error-placeholder {
      & > p:nth-child(2),
      & > p:nth-child(3) {
         color: rgba(36, 42, 51, 0.92);
      }
   }
}
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding-top: 32px;

  &-title {
      margin: 24px 0;
      font-weight: 500;
      font-size: 24px;
      line-height: 34px;
      letter-spacing: -0.5px;
  }

  button {
      width: 110px;
      height: 40px;
  }
}
.error-wrapper {
   height: 100vh;
   display: flex;

   .error-placeholder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      & > p:nth-child(2) {
         margin-top: 24px;
         margin-bottom: 8px;
         font-weight: 500;
         font-size: 24px;
         line-height: 34px;
         letter-spacing: -0.5px;
      }

      & > p:nth-child(3) {
         text-align: center;
         max-width:  450px;
         letter-spacing: 0.15px;
         color: rgba(255, 255, 255, 0.5);
         line-height: 24px;
      }
   }
}
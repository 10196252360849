.group-item {
   width: 532px;
   height: 313px;
   background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #0A0E23;
   border-radius: 12px;
   border: 1px solid transparent;

   &:hover {
      border: 1px solid rgba(255, 255, 255, 0.08);
      box-shadow: 0px 8px 38px 7px rgba(0, 0, 0, 0.12), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 10px 13px -6px rgba(0, 0, 0, 0.20);

      .group-title {
         background: linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.11) 100%), #090D23;
      }

      .group-body,
      .waiting-wrapper,
      .disabled-wrapper {
         background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), #050A23;
         border-bottom-left-radius: 12px;
         border-bottom-right-radius: 12px;
      }
   }

   .group-title {
      display: flex;
      max-height: 62px;
      align-items: center;
      justify-content: space-between;
      padding: 10px 24px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      & > div:first-child {
         display: flex;
         align-items: center;
         gap: 12px;

         p {
            max-width: 400px;
            text-overflow: ellipsis;
            overflow: hidden;

            @media (max-width: 900px) {
               max-width: 200px;
            }
         }
      }

      @media (max-width: 900px) {
         padding: 21px 16px;
      }
   }

   .group-body {
      display: flex;
      height: 253px;
      align-items: center;

      & > div:first-child {
         padding-top: 24px;
         padding-left: 32px;
         padding-bottom: 24px;
      }

      @media (max-width: 900px) {
         padding: 24px 16px;
         flex-direction: column;

         & > div:first-child {
            padding-top: unset;
            padding-left: unset;
            padding-bottom: unset;
         }
      }
   }

   .speedometer-wrapper {
      display: inline-block;
      text-align: center;
   }

   .speedometer-label {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.25px;
   }

   .speedometer-sublabel {
      font-size: 12px;
      line-height: 166%;
      letter-spacing: 0.4px;
      color: rgba(255, 255, 255, 0.5);
   }

   .view-report-button {
      width: 114px;
      height: 40px;
      margin-top: 24px;
   }

   .vertical-line {
      align-self: normal;
      width: 1px;
      margin-left: 26.5px;
      margin-right: 32px;
      background-color: rgba(255, 255, 255, 0.06);
   }

   .info-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
      padding-right: 32px;

      @media (max-width: 900px) {
         padding-right: unset;
      }
   }

   .info-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > div:first-child {
         p:first-child {
            max-width: 240px;
            font-weight: 500;
            line-height: 24px;
            text-overflow: ellipsis;
            overflow: hidden;

            @media (max-width: 450px) {
               max-width: 180px;
            }
         }
      }

      svg {
         transform: scale(1.6);
      }

      @media (max-width: 900px) {
         margin-top: 24px;
      }
   }

   .line {
      height: 1px;
      background-color: rgba(255, 255, 255, 0.06);
   }

   .info-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 14px;
   }

   .info-item {
      .info-item-title {
         margin-bottom: 2px;
         font-size: 12px;
         line-height: 20px;
         letter-spacing: 0.4px;
         color: rgba(255, 255, 255, 0.7);
      }

      .info-item-value {
         display: flex;
         align-items: center;
         gap: 8px;

         p {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.17px;
         }
      }
   }

   .waiting-wrapper,
   .disabled-wrapper {
      display: flex;
      align-items: center;
      height: 232px;

      & > div:first-child {
         display: flex;
         align-items: center;
         justify-content: center;
         width: 173px;
      }

      .vertical-line {
         margin-left: 0;
      }

      @media (max-width: 900px) {
         height: unset;
         flex-direction: column;
         padding: 24px 0;
         gap: 24px;
         text-align: center;

         .vertical-line {
            display: none;
         }
      }
   }

   .waiting-info {
      p:first-child {
         margin-bottom: 3px;
         font-weight: 500;
         line-height: 24px;
      }

      p:nth-child(2) {
         font-size: 12px;
         line-height: 20px;
         letter-spacing: 0.4px;
         color: rgba(255, 255, 255, 0.7);
      }

      p:last-child {
         font-size: 14px;
         line-height: 20px;
         letter-spacing: 0.17px;
         color: #17EDC3;
         cursor: pointer;
      }
   }

   .disabled-text {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.17px;
      color: rgba(255, 255, 255, 0.7);
   }

   .turn-off-icon-wrapper {
      cursor: pointer;
      color: rgba(255, 255, 255, 0.56);

      &:hover {
         color: #17EDC3;
      }
   }

   .date-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;

      p {
         font-size: 12px;
         line-height: 20px;
         letter-spacing: 0.4px;
         color: rgba(255, 255, 255, 0.7);
      }
   }

   .subject {
      white-space: nowrap;
   }

   .email-provider-image {
      width: 32px;
      height: 32px;
   }

   @media (max-width: 900px) {
      height: unset;
   }
}
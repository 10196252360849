.history-modal-wrapper {
  width: calc(600px - 48px);
  padding-top: 24px;

  .ip-name {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;

    .status-icon {
      min-width: 48px;
      min-height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 64px;

      svg {
        scale: 1.2;
      }

      @media (max-width: 700px) {
        min-width: 32px;
        min-height: 32px;
      }
    }
    
    .healthy {
        background: rgba(23, 237, 195, 0.16);
    }
    
    .down {
        background: rgba(255, 167, 38, 0.3);
    }
    
    .blacklisted {
        background: rgba(240, 43, 79, 0.3);
    }
  
    .default {
      background: rgba(255, 255, 255, 0.3);
  
      path {
         fill: white;
      }
    }

    .ip-title-wrapper {
      & > div {
        display: flex;
        align-items: center;
        gap: 20px;

        & > p:first-child {
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: 0.25px;
        }
      }

      & > p:last-child {
        font-size: 12px;
        line-height: 166%;
        letter-spacing: 0.4px;
        color: rgba(255, 255, 255, 0.7);
        max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 700px) {
          max-width: 200px;
        }
      }
    }
  }

  .loader {
    display: block;
    margin: 80px auto;
  }

  .blacklist {
    padding: 16px 24px;
    margin-bottom: 20px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.11) 100%), #090D24;
    border-radius: 8px;

    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.15px;
      color: rgba(255, 255, 255, 0.7);
    }

    .history-days {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 8px;

      @media (max-width: 700px) {
        flex-wrap: wrap;
        justify-content: space-around;
      }
    }

    .history-item {
      &:hover {
        margin-top: -2px;

        & > div:first-child {
          margin-bottom: 4px;
          height: 18px;
        }
      }

      & > div:first-child {
        width: 43.2px;
        height: 14px;
        border-radius: 2px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #0D1124;
        margin-bottom: 6px;
      }

      .date-label {
        font-size: 12px;
        line-height: 166%;
        text-align: center;
        letter-spacing: 0.4px;
        color: rgba(255, 255, 255, 0.5);
      }

      &-success > div:first-child {
        background: #17EDC3;
      }

      &-error > div:first-child {
        background: #F02B4F;
      }
    }
  }

  .show-details {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
  
    p, svg {
      color: rgba(255, 255, 255, 0.7);
    }
  
    p {
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;
      letter-spacing: 0.46px;
    }
  }

  @media (max-width: 700px) {
    width: 300px;
  }
}

.history-days-tooltip-title p {
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
}

.api-key-wrapper {
   .api-key-item {
      display: flex;
      align-items: center;
      padding: 16px 24px;
   
      &:hover {
         background: rgba(0, 0, 0, 0.3);
   
         .item-actions {
            position: absolute;
            right: 29px;
            display: flex;
            gap: 26px;
         }
      }
   }

   .item-actions {
      display: none;

      svg {
         cursor: pointer;
      }
   }

   .name-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
      height: 40px;
      background: rgba(23, 237, 195, 0.16);
      border-radius: 64px;
   }

   .name-wrapper {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 30%;

      p {
         text-overflow: ellipsis;
         white-space: nowrap;
         overflow: hidden;
      }
   }

   .key-wrapper {
      min-width: 300px;
      margin-left: 10%;
      
      p {
         max-width: 250px;
         text-overflow: ellipsis;
         white-space: nowrap;
         overflow: hidden;
         font-size: 14px;
         line-height: 20px;
         letter-spacing: 0.17px;
      }
   }

   .created-wrapper {
      margin-left: 5%;

      p {
         font-size: 14px;
         line-height: 20px;
         letter-spacing: 0.17px;
         color: rgba(255, 255, 255, 0.7);
      }
   }

   .line {
      height: 1px;
      background-color: rgba(255, 255, 255, 0.06);
   }
}
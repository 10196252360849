.add-email-account-modal-wrapper {
  width: 600px;
  padding-top: 24px;

  .email-items-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .add-email-item {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    border-radius: 12px;
    background: var(--dark-background-paper-elevation-4, linear-gradient(180deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0.09) 100%), #0B0F24);
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
      border: 1px solid var(--dark-primary-dark, #009688);
      background: var(--dark-primary-shades-16-p, rgba(23, 237, 195, 0.16));
    }
  }

  @media (max-width: 700px) {
    width: 300px;
  }
}
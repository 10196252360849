.email-infrastructure{
  margin-bottom: 90px;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    &-title {
      font-size: 24px;
      font-weight: 500;
    }

    &-report {
      display: flex;
      color: #FFFFFFB2;
      align-items: center;
      cursor: pointer;

      &-text {
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }

  &-content {
    width: 100%;
    display: flex;
    align-items: center;
    height: 224px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0.09) 100%), #0B0F24;
    border-radius: 12px;
    padding: 32px;

    &-diagram {
      width: 292px;
      height: 160px;
      padding: 0 82px 0 50px;
      border-right: 1px solid #FFFFFF0F;

      .score-circle {
        margin-top: -8px;
      }
    }

    &-items-wrapper {
      height: 100%;
      flex: 1;
      width: 100%;
      padding-left: 32px;

      .parent-grid {
        width: 100%;
        height: 100%;
      }
    }

    &-items-row {
      display: flex;
      align-items: center;

      &:first-child {
        padding-bottom: 25px;
        border-bottom: 1px solid #FFFFFF0F;
        margin-bottom: 25px;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      height: 55px;
      flex: 1;
      padding-left: 32px;
      border-right: 1px solid #FFFFFF0F;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        border-right: 0;
      }

      p {
        margin-left: 16px;
      }
    }
  }
}
.used-modal-wrapper {
  width: 444px;
  padding-top: 16px;

  .content {
    display: flex;
    flex-direction: column;

    p {
      line-height: 24px;
      letter-spacing: 0.15px;
    }

    .close-button,
    .buttons {
      margin-top: 24px;
      align-self: flex-end;
    }

    .close-button {
      height: 40px;
      width: 69px;
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .cancel-button {
      height: 40px;
      width: 77px;
    }

    .delete-button {
      height: 40px;
      width: 160px;
    }
  }

  .loader {
    display: block;
    margin: 25px auto;
  }

  .check-select-wrapper {
    margin-top: 16px;

    & > p:first-child {
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.25px;
    }

    .check-select {
      width: 444px;

      @media (max-width: 500px) {
        width: 320px;
      }
    }
  }

  .select-value {
    p:first-child {
       font-size: 12px;
       line-height: 12px;
       letter-spacing: 0.15px;
       color: rgba(255, 255, 255, 0.7);
       margin-bottom: 3px;
    }
  }

  @media (max-width: 500px) {
    width: 320px;
  }
}
.pagination {
  display: flex;
  align-items: center;
  list-style: none;

  ul {
    gap: 6px;
  }

  li {
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.08);
    }
  }
}
.email-mxrisk-result-wrapper {
   max-width: 1200px;
   padding: 48px;
   padding-bottom: calc(48px + 128px);

   .title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
   }

   .title {
      p {
         font-weight: 700;
         font-size: 32px;
         line-height: 44px;
         letter-spacing: -0.5px;

         @media (max-width: 500px) {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
         }
      }
   }

   .breadcrumbs {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      line-height: 143%;
      letter-spacing: 0.17px;
      color: rgba(255, 255, 255, 0.5);

      p:first-child {
         color: #009688;
      }
   }

   .result-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 33px;

      @media (max-width: 500px) {
         margin-top: 25px;
      }
   }

   .close-button {
      width: 93px;
      height: 40px;
      margin-top: 32px;
      margin-left: auto;

      path {
         fill-opacity: 1;
         fill: white;
      }
   }

   .button-icon {
      position: relative;
      top: unset;
      left: unset;
      height: 20px;
      margin-right: 6px;
   }

   @media (max-width: 500px) {
      padding: 32px 16px;
   }
}

.unauthorized-wrapper .content {
   max-width: unset;
}
.connected-email {
  padding: 24px 32px 32px;
  width: 331px;
  box-sizing: border-box;
  border-right: 1px solid #FFFFFF0F;

  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.1px;

      &:last-child {
        color: #0091FF;
      }
    }
  }

  &-description {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 4px;
    color: #FFFFFFB2;
    font-weight: 400;
  }

  &-controls {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    .copy-to-clipboard-button,
    .copy-csv-button {
      color: #FFFFFFB2;
      padding: 0;
      height: 30px;

      &:hover {
        background-color: initial;
        border: unset;
      }
    }

    .copy-csv-button {
      width: 140px;
      height: 30px;
      font-weight: 500;
      font-size: 13px;
    }

    .copy-to-clipboard-button {
      width: 180px;
    }

    .copy-icon {
      position: static;
      color: #FFFFFFB2;
      margin: 0 4px 0 -5px;
    }

    .copy-as-select-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        font-size: 13px;
        color: #FFFFFFB2;
        font-weight: 500;
      }
    }
  }

  .progress-bar {
    height: 8px;
    margin-bottom: 16px;
    border-radius: 3px;
    background-color: rgba(0, 145, 255, 0.2);

    span {
      background-color: #0091FF;
    }
  }
}

.rename-template-group-modal-wrapper {
  width: 600px;
  padding-top: 24px;

  .confirm-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 40px;

    button:first-child {
       width: 77px;
       height: 40px;
    }

    button:last-child {
      width: 63px;
      height: 40px;
   }
  }

  @media (max-width: 700px) {
    width: 300px
  }
}
.maintenance-wrapper {
   display: flex;

   .sidebar {
      position: fixed;
      min-width: 256px;
      height: 100vh;
      padding: 24px 0px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #0D1124;
      text-align: center;
      border-right: 1px solid rgba(255, 255, 255, 0.06);
   }

   .line {
      height: 1px;
      margin: 32px 24px;
      background-color: rgba(255, 255, 255, 0.06);
   }

   .menu {
      & > div {
         display: flex;
         align-items: center;
         padding: 12px 24px;
         gap: 20px;
      }

      .circle,
      .label {
         background: #484D62;
         border-radius: 14px;
      }
   
      .circle {
         width: 20px;
         height: 20px;
      }

      .label {
         height: 6px;
      }
   }

   .content {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      height: calc(100vh - 128px);
      padding-left: 256px;

      .title {
         margin-top: 30px;
         margin-bottom: 8px;
         font-weight: 500;
         font-size: 24px;
         line-height: 34px;
         letter-spacing: -0.5px;
      }

      .text {
         max-width: 488px;
         line-height: 24px;
         letter-spacing: 0.15px;
         color: rgba(255, 255, 255, 0.5);
      }
   }
}
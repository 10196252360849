.seed-list-in-progress {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 331px;
  padding: 24px 32px 32px;
  box-sizing: border-box;
  border-right: 1px solid #FFFFFF0F;

  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.1px;

      &:last-child {
        color: #0091FF;
      }
    }
  }

  &-time {
    color: rgba(255, 255, 255, 0.70);
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.4px;
  }

  .progress-bar {
    height: 8px;
    border-radius: 3px;
    background-color: rgba(0, 145, 255, 0.2);

    span {
      background-color: #0091FF;
    }
  }
}

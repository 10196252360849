.menu-item {
   gap: 12px;

   p {
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;
   }

   .status-swich {
      margin-left: 20px;
   }
}

.menu-line {
   height: 1px;
   background-color: rgba(255, 255, 255, 0.06);
}
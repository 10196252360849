.edit-email-provider-modal-wrapper {
  width: 600px;
  padding-top: 24px;

  .select-value {
    p:first-child {
       font-size: 12px;
       line-height: 12px;
       letter-spacing: 0.15px;
       color: rgba(255, 255, 255, 0.7);
       margin-bottom: 3px;
    }
  }

  .confirm-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 30px;

    button {
      height: 40px;
    }

    .cancel-button {
      width: 77px;
    }

    .save-button {
      width: 63px;
    }
  }

  .select-wrapper {
    flex: 1;
  }

  .loader {
    display: block;
    margin: 70px auto;
  }

  .line {
    width: calc(100% + 48px);
    margin: 12px 0;
    margin-left: -24px;
    height: 1px;
    background: rgba(255, 255, 255, 0.06);
  }

  .MuiIconButton-root {
    margin-right: unset;
    margin-top: unset;
    padding-right: 8px;
  }

  .more-error-info {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 2px;
    margin-right: 8px;
    cursor: pointer;

    p {
      color: #f8b0b4;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.46px;
    }
  }

  @media (max-width: 700px) {
    width: 300px;
  }
}

.edit-email-provider-modal {
  overflow: visible;
}
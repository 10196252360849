.add-email-mx-risk-modal-wrapper {
  width: 600px;
  padding-top: 24px;

  .select-value {
    p:first-child {
       font-size: 12px;
       line-height: 12px;
       letter-spacing: 0.15px;
       color: rgba(255, 255, 255, 0.7);
       margin-bottom: 3px;
    }
  }

  .confirm-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 40px;

    button:first-child {
       width: 77px;
       height: 40px;
    }

    button:last-child {
      width: 63px;
      height: 40px;
   }
  }

  .select-wrapper {
    width: 100%;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .loader {
    display: block;
    margin: 70px auto;
  }

  @media (max-width: 700px) {
    width: 300px;
  }
}

.add-email-mx-risk-modal {
  overflow: visible;
}
.cookie-consent-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;

  a {
    color: rgba(255, 255, 255, 0.7);
  }
}

button.cookie-button {
  width: 100px;
  height: 40px;
}
.pricing-plan-error-modal-wrapper {
  .list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 40px;
  }

  .confirm-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 40px;

    & > button {
       width: 76px;
       height: 40px;
    }
  }
}
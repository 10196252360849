.deliverability-test-start-new-wrapper {
  max-width: 1200px;
  padding: 48px;

  .start-new-title {
    margin-bottom: 5px;

    .title-show-mode {
       display: flex;
       align-items: center;
       gap: 16px;

      p {
        max-width: 700px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
        letter-spacing: -0.5px;

        @media (max-width: 900px) {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
        }
      }

      .edit-icon-container {
        cursor: pointer;
      }
    }

    .title-edit-mode {
      display: inline-flex;
      gap: 8px;

      .MuiInputBase-root {
        border: 1px solid #17EDC3;

        .MuiInputBase-input {
          padding-top: 12px;
        }
      }

      .title-input-error {
        .MuiInputBase-root {
          border-color: #F02B4F;
        }
      }

      .title-check-icon-button {
        width: 32px;
        height: 32px;
        margin-top: 6px;
        cursor: pointer;
      }
    }
  }

 .breadcrumbs {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: rgba(255, 255, 255, 0.5);

    p:first-child {
       color: #009688;
    }
  }

  .deliverability-test-form {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #0A0E23;
    padding: 32px;
    margin-top: 32px;
    border-radius: 12px;

    &-radio-wrapper {
      display: flex;
      align-items: center;

      .MuiFormControlLabel-root {
        margin-right: 5px;

        &:first-child {
          margin-right: 24px;
        }
      }
    }

    .line {
      width: 100%;
      margin: 24px 0;
      height: 1px;
      background: rgba(255, 255, 255, 0.06);
    }

    &-controls {
      display: flex;
      justify-content: flex-end;

      button {
        width: 105px;
        height: 40px;
      }
    }
  }
}
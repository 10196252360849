.template-mx-risk-wrapper {
   display: flex;
   flex-direction: column;
   flex: 1;
   padding: 48px;

   .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 34px;
      gap: 16px;

      & > p:first-child {
         font-weight: 700;
         font-size: 32px;
         line-height: 44px;
         letter-spacing: -0.5px;

         @media (max-width: 500px) {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
         }
      }
   }

   .list-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
   }

   .table-pagination {
      margin-left: auto;
   }

   .monitor-list {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      margin-bottom: 32px;

      @media (max-width: 1250px) {
         justify-content: center;
      }
   }

   .button-icon {
      position: relative;
      top: unset;
      left: unset;
      height: 20px;
      margin-right: 8px;
   }

   .load-more-btn {
      margin: 0 auto;
   }

   .loader {
      display: block;
      margin: auto;
   }

   .empty-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      padding-top: 32px;

      &-title {
         margin: 24px 0;
         font-weight: 500;
         font-size: 24px;
         line-height: 34px;
         letter-spacing: -0.5px;
      }

      button {
         width: 168px;
         height: 40px;
      }
   }

   button.test-template-button {
      width: 168px;
      height: 40px;
      padding-right: 0;

      .test-template-button-content {
         display: flex;
         align-items: center;

         p {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
         }

         & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            margin-left: 16px;
            margin-right: -6px;
            padding-left: 10px;
            padding-right: 16px;
            border-left: 1px solid #009688;
         }
      }
   }

   @media (max-width: 500px) {
      padding: 38px 16px;
   }
}
.set-new-password-wrapper {
   max-width: 500px;
   padding: 48px 80px 56px;
   text-align: center;

   & > p {
      margin: 24px 0;
      font-size: 24px;
      line-height: 34px;
      font-weight: 500;
   }

   .text {
      color: rgba(255, 255, 255, 0.7);
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
   }

   .sign-in {
      text-align: center;
      margin: 24px auto 0;
      font-size: 14px;
   }

   .reset-password {
      margin-top: 24px;
   }

   .password-input {
      margin-bottom: 12px;
   }
}
.template-mx-risk-folder-wrapper {
   max-width: 1300px;
   flex: 1;
   padding: 48px 48px 0;

   .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;

      .title-show-mode {
         display: flex;
         align-items: center;
         gap: 16px;

         p {
            max-width: 700px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-weight: 700;
            font-size: 32px;
            line-height: 44px;
            letter-spacing: -0.5px;

            @media (max-width: 500px) {
               max-width: 200px;
               font-weight: 500;
               font-size: 20px;
               line-height: 28px;
            }
         }
      }

      .title-edit-mode {
         display: inline-flex;
         align-items: center;
         gap: 8px;
      }
   }
   
   .name-input {
      width: 360px;

      & > div {
         border: 1px solid #17EDC3;
      }

      input {
         padding-top: 8.5px;
         padding-bottom: 8.5px;
      }

      @media (max-width: 1000px) {
         width: unset;
      }
   }

   .check-icon-button {
      width: 40px;
      height: 40px;
      background-color: rgba(23, 237, 195, 0.12);
      border-radius: 4px;

      &:hover {
         background-color: rgba(23, 237, 195, 0.2);
      }

      &-disabled {
         path {
            fill: rgba(255, 255, 255, 0.3);
         }
      }
   }

   .edit-icon-container {
      cursor: pointer;
   }

   .breadcrumbs {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      line-height: 143%;
      letter-spacing: 0.17px;
      color: rgba(255, 255, 255, 0.5);

      p:first-child,
      p:nth-child(3) {
         color: #009688;
      }
   }

   .list {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 20px 0;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
      border-radius: 12px;
   }

   .mobile-list {
      display: flex;
      flex-direction: column;
      gap: 16px;
   }

   .list,
   .mobile-list {
      margin: 32px 0;
   }

   .mobile-table-pagination,
   .table-pagination {
      margin-left: auto;
   }

   .table-pagination {
      margin-top: 20px;
      margin-right: 24px;
   }

   .table-loader {
      position: absolute;
      left: 50%;
      top: 50%;
   }

   .loader {
      display: block;
      margin: auto;
   }

   .empty-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;
      height: calc(100vh - 389px);
      margin-top: 33px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
      border-radius: 12px;

      .title {
         font-weight: 500;
         font-size: 24px;
         line-height: 34px;
         text-align: center;
         letter-spacing: -0.5px;
      }

      .description {
         line-height: 24px;
         text-align: center;
         letter-spacing: 0.15px;
         color: rgba(255, 255, 255, 0.5);

         span {
            display: block;
            cursor: pointer;
            color: rgba(23, 237, 195, 1);
         }
      }
   }

   .table-head {
      display: flex;
      align-items: center;
      width: 90%;
      padding: 6px 0;
      padding-left: 24px;

      .name-head {
         min-width: 200px;
      }

      .mx-risk-head {
         min-width: 150px;
         margin-left: 10%;
      }

      .readability-head {
         min-width: 100px;
         margin-left: 5%;
      }

      .sentiment-assasin-head {
         min-width: 100px;
         margin-left: 5%;
      }

      .check-date-head {
         margin-left: 5%;
      }

      p {
         font-weight: 500;
         font-size: 14px;
         line-height: 24px;
         letter-spacing: 0.17px;
         color: rgba(255, 255, 255, 0.5);
      }
   }

   .table-head-line {
      height: 1px;
      background: rgba(255, 255, 255, 0.04);
   }

   button.test-template-button {
      width: 155px;
      height: 40px;

	   @media (max-width: 500px) {
         min-width: 40px;
         width: 40px;

         .button-icon {
            margin-right: 0;
         }
      }
   }

   .button-icon {
      position: relative;
      top: unset;
      left: unset;
      height: 20px;
      margin-right: 8px;
   }

   .title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 8px;
   }

   @media (max-width: 500px) {
      padding: 40px 16px;
   }
}
.add-ip-monitor-modal-wrapper {
  width: 600px;
  padding-top: 24px;

  .add-ip-monitor-form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 24px;
  }

  .line {
    width: calc(100% + 48px);
    height: 1px;
    margin-left: -24px;
    background-color: rgba(255, 255, 255, 0.06);
  }

  .check-frequency {
    padding-top: 24px;

    &-container {
      display: flex;
      justify-content: space-between;
    }

    @media (max-width: 700px) {
      padding-bottom: 24px;

      &-container {
        flex-direction: column;
        gap: 16px;
      }
    }
  }

  .section-title {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.25px;
  }

  .pro-label {
    display: flex;
    align-items: center;
    gap: 10px;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 37px;
      height: 20px;
      background: #17EDC3;
      border-radius: 64px;

      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.14px;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }

  .select {
    height: 56px;
  }

  .check-select {
    width: 268px;
  }

  .select-value {
    & > p:first-child {
       font-size: 12px;
       line-height: 12px;
       letter-spacing: 0.15px;
       color: rgba(255, 255, 255, 0.7);
       margin-bottom: 3px;
    }
  }

  .notifications {
    padding-top: 24px;
  }

  .notification-preset-name-input {
    width: 269px;

    input {
      padding: 8px 12px;
    }
  }

  .notification-preset-name-wrapper {
    display: flex;
    align-items: flex-end;
  }

  .notification-custom-settings {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 17px;
    padding: 24px 16px 16px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.11) 100%), #090D23;
    border-radius: 4px;
  }

  .switch-container {
    margin-left: 0;

    &-checked {
      margin-bottom: 15px;
    }
  }

  .emails-textarea > div:first-child {
    padding: 9px 12px;
  }

  .helper-text {
    margin-top: 3px;
    margin-left: 14px;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: rgba(255, 255, 255, 0.7);

    &-error {
      color: #F02B4F;
    }
  }

  .switch-label {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .comming-soon-label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 6.5px;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 64px;

    p {
      color: white;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.14px;
    }
  }

  .confirm-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 40px;

    & > button {
       width: 76px;
       height: 40px;
    }
  }

  .general-error-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 32px;
  }

  .general-error-wrapper {
    display: flex;
    align-items: center;
    padding: 14px 16px;
    gap: 12px;
    background-color: #F02B4F;
    border-radius: 4px;

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;
    }
  }

  .custom-preset-input {
    & input {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  @media (max-width: 700px) {
    width: unset;
  }
}

span.add-ip-monitor-loader {
  display: block;
  margin: 325px 280px;

  @media (max-width: 700px) {
    margin: 325px 120px;
  }
}

p:has(.check-select-label) {
  width: 100%;
}

.check-select-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &-disabled {
    & p {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .upgrade-plan-button {
    z-index: 1;
    display: flex;
    height: 24px;
  }
}

.score-circle {
  padding-top: 50px;
  margin-bottom: 50px;

  & > div {
    position: relative;
  }

  & > p {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: -50px;
  }

  .mxr-mark {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 51%;
    margin-top: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 52px;
    font-weight: 600;
  }

  .mxr-pass-percentage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -50px;

    p {
      margin-left: 8px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.7);
    }
  }
}

.circle-loader {
  position: relative;

  .waiting-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    path { fill: rgba(255, 255, 255, 0.05); }
  }

  & > p,
  .blured-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & > p {
    font-size: 12px;
    line-height: 166%;
    text-align: center;
    letter-spacing: 0.4px;
    color: rgba(255, 255, 255, 0.5);
  }

  .blured-wrapper {
    & > div {
      background: linear-gradient(277.03deg, rgba(67, 71, 96, 0.49) 10.63%, #434760 88.89%);
      height: 8px;
      border-radius: 6px;
      margin: 0 auto;

      &:first-child {
        width: 67px;
        margin-bottom: 13px;
      }

      &:last-child {
        width: 50px;
      }
    }
  }
}

.circle-mark {
  position: relative;

  .mxr-mark {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    margin-top: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 52px;
    font-weight: 600;
  }

  .dash {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    margin-top: 0;
    margin-left: 1px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.18);
    font-size: 22px;
    font-weight: 600;
    line-height: 20px;
  }

  &_medium {
    width: 48px;
    
    .mxr-mark {
      font-size: 22px;
    }
  }

  &_small {
    width: 40px;
    
    .mxr-mark {
      font-size: 16px;
    }
  }

  &_tiny {
    width: 24px;
    
    .mxr-mark {
      font-size: 12px;
    }
  }
}

.deliverability-test-form {
  &-email-input-wrapper {
    margin-bottom: 12px;

    .MuiInputBase-root {
      height: 56px;
      width: 400px;
    }
  }
}

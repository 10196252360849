.inbox-report-history-modal-wrapper {
  display: flex;
  flex-direction: column;
  width: 912px;
  box-sizing: border-box;
  padding-top: 16px;

  .last-test-date-block {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    p {
        font-size: 14px;
        color: #FFFFFFB2;
        margin-left: 12px;
    }
  }

  .line {
    border: 1px solid rgba(255, 255, 255, 0.06);
    height: 1px;
    margin-bottom: 24px;
    width: calc(100% + 48px);
    margin-left: -24px;
  }

  .inbox-report-statistic {
    display: flex;
    gap: 24px;
    margin-bottom: 24px;

    &-score {
      position: relative;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.11) 100%), #090D24;
      width: 288px;
      height: 159px;
      border-radius: 8px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding-top: 6px;

      .gauge-container {
        & > .gauge .dial {
          stroke: #141623;
          stroke-width: 7;
          stroke-linecap: round;
        }

        & > .gauge .value {
          stroke-dasharray: none;
          stroke-width: 7;
          stroke-linecap: round;
        }
      }

      &-info {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 70px;
        left: 90px;

        &-percents {
          font-size: 28px;
          font-weight: 600;
          color: #17EDC3;
          line-height: 40px;
        }

        &-text {
          font-size: 14px;
          color: #FFFFFFB2;
          line-height: 22px;
        }
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.11) 100%), #090D24;
      width: 184px;
      height: 159px;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 24px;

      & > svg {
        width: 24px;
        height: 24px;
      }

      &-name {
        font-size: 14px;
        font-weight: 500;
        color: #FFFFFFB2;
        line-height: 22px;
      }

      &-value {
        font-size: 24px;
        font-weight: 500;
        line-height: 34px;
      }
    }
  }

  .inbox-report-diagram {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      p {
        font-size: 18px;
        font-weight: 500;
      }

      .select-value {
        font-size: 14px;
      }

      .MuiSelect-select {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), #050A23;
        border: 1px solid #FFFFFF8F;
      }
    }

    &-content {
      width: 100%;
      height: 305px;
      border-radius: 8px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.11) 100%), #090D24;
      box-sizing: border-box;
      padding: 24px;
    }
  }
}

.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 90vh;
  overflow: auto;
  transform: translate(-50%, -50%);
  padding: 16px 24px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), #050A23;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      max-width: 410px;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.25px;
    }
  }
}
.email-template-mxrisk-result-wrapper {
   max-width: 1200px;
   padding: 48px;
   padding-bottom: calc(48px + 128px);

   .title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
   }

   .title {
      .title-show-mode {
         display: flex;
         align-items: center;
         gap: 16px;

         p {
            max-width: 700px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-weight: 700;
            font-size: 32px;
            line-height: 44px;
            letter-spacing: -0.5px;

            @media (max-width: 500px) {
               font-weight: 500;
               font-size: 20px;
               line-height: 28px;
             }
         }
      }

      .title-edit-mode {
         display: inline-flex;
         align-items: center;
         gap: 8px;
      }
   }

   .breadcrumbs {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      line-height: 143%;
      letter-spacing: 0.17px;
      color: rgba(255, 255, 255, 0.5);

      p:first-child {
         color: #009688;
      }
   }

   .result-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 33px;

      @media (max-width: 500px) {
         margin-top: 25px;
      }
   }

   .edit-icon-container {
      cursor: pointer;
   }

   .name-input {
      width: 360px;

      & > div {
         border: 1px solid #17EDC3;
      }

      input {
         padding-top: 8.5px;
         padding-bottom: 8.5px;
      }

      @media (max-width: 500px) {
         width: 250px;
      }
   }

   .check-icon-button {
      width: 40px;
      height: 40px;
      background-color: rgba(23, 237, 195, 0.12);
      border-radius: 4px;

      &:hover {
         background-color: rgba(23, 237, 195, 0.2);
      }

      &-disabled {
         path {
            fill: rgba(255, 255, 255, 0.3);
         }
      }
   }

   .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 32px;
      gap: 16px;
   }

   .close-button,
   .edit-button {
      path {
         fill-opacity: 1;
         fill: white;
      }
   }

   .close-button {
      width: 93px;
      height: 40px;
   }

   .edit-button {
      width: 82px;
      height: 40px;

      &-disabled {
         path {
            fill-opacity: 0.3;
         }
      }
   }

   .button-icon {
      position: relative;
      top: unset;
      left: unset;
      height: 20px;
      margin-right: 6px;
   }

   @media (max-width: 500px) {
      padding: 32px 16px;
   }
}

.unauthorized-wrapper .content {
   max-width: unset;
}
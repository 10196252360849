.unauthorized-wrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   min-height: 100vh;
   padding-top: 48px;
   padding-bottom: 120px;
   background-color: #141623;

   .content {
      max-width: 544px;
      margin-top: 40px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #0D1124;
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
   }
}
.ip-monitor-item {
   display: flex;
   flex-direction: column;
   width: 340px;
   height: 290px;
   background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #0A0E23;
   border-radius: 12px;
   border: 1px solid transparent;
   cursor: pointer;

   & > div:first-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
   }

   &:hover {
      border: 1px solid rgba(255, 255, 255, 0.08);
      box-shadow: 0px 8px 38px 7px rgba(0, 0, 0, 0.12), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 10px 13px -6px rgba(0, 0, 0, 0.20);

      & > div:first-child {
         background: linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.11) 100%), #090D23;
      }

      .ip-list-container {
         background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), #050A23;
         border-bottom-left-radius: 12px;
         border-bottom-right-radius: 12px;
      }
   }

   .ip-monitor-title-container {
      display: flex;
      align-items: center;
      gap: 16px;
      white-space: nowrap;
      cursor: pointer;

      .ip-monitor-title {
         width: 170px;
         display: flex;
         flex-direction: column;
         gap: 4px;

         .monitor-name {
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: 0.25px;
            overflow: hidden;
            text-overflow: ellipsis;
         }

         .monitor-status {
            font-size: 12px;
            line-height: 166%;
            letter-spacing: 0.4px;

            &-default {
               color: rgba(255, 255, 255, 0.7);
            }

            &-error {
               color: #F02B4F;
            }
         }
      }
   }
   
   .circle {
      width: 8px;
      height: 8px;
      margin-right: 11px;
      border-radius: 100%;

      &-success {
         background-color: #17EDC3;
      }

      &-warning {
         background-color: #FFA726;
      }

      &-error {
         background-color: #F02B4F;
      }

      &-default {
         background-color: rgba(255, 255, 255, 0.18);
      }
   }

   .label {
      font-size: 12px;
      line-height: 166%;
      letter-spacing: 0.4px;

      &-success {
         color: rgba(255, 255, 255, 0.7);
      }

      &-warning {
         color: #FFA726;
      }

      &-error {
         color: #F02B4F;
      }

      &-default {
         color: rgba(255, 255, 255, 0.5);
      }
   }
   
   .ip-address {
      display: flex;
      align-items: center;

      p {
         font-size: 14px;
         line-height: 143%;
         letter-spacing: 0.17px;
      }
   }
   
   .watching-eye {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 48px;
      min-height: 48px;
      background: rgba(255, 255, 255, 0.08);
      border-radius: 64px;
      fill-opacity: 0.3;
   }
   
   .active-watcing-eye {
      background: rgba(23, 237, 195, 0.16);
      color: #17EDC3;
      fill-opacity: 1;
   }
   
   .ip-list-container {
      flex: 1;
      padding: 10px 32px 0;
      overflow-y: auto;
   
      & > div {
         display: flex;
         align-items: center;
         justify-content: space-between;
         padding: 7px 0;
         gap: 10px;
         font-size: 14px;
         line-height: 143%;
         letter-spacing: 0.17px;
      }

      &-disabled {
         display: flex;
         align-items: center;
         justify-content: center;
         padding: 0;
      }
   }
   
   div.more-container {
      margin-left: -4px;
      justify-content: start;
      line-height: 143%;
      letter-spacing: 0.17px;
      color: #17EDC3;
      cursor: pointer;
   }

   div.monitor-disabled-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      gap: 16px;

      p {
         font-size: 14px;
         line-height: 143%;
         letter-spacing: 0.17px;
         color: rgba(255, 255, 255, 0.7);
      }
   }
}
.ip-row-container {
   &:hover {
      background: rgba(0, 0, 0, 0.3);

      .item-actions {
         position: absolute;
         right: 20px;
         display: flex;
         gap: 4px;
      }

      .icon-wrapper-pencil {
         display: flex;
      }

      .ip-address-wrapper {
         .magnifying-glass-icon {
            display: block;
         }
      }
   }

   .ip-row {
      display: flex;
      align-items: center;
      width: 90%;
      padding-left: 24px;
      padding-top: 16px;

      &-disabled {
         color: rgba(255, 255, 255, 0.5);
      }
   
      & > div:first-child {
         display: flex;
         align-items: center;
         width: 35%;
      }

      .ip-address {
         display: flex;
         align-items: center;
         min-width: 160px;     
      }
   }

   .mobile-ip-row {
      display: flex;
      justify-content: space-between;
      padding: 16px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #0A0E23;
      border-radius: 12px;
   }

   .mobile-icon-and-item {
      display: flex;
   }

   .mobile-item-info {
      p {
         max-width: 200px;
         text-overflow: ellipsis;
         white-space: nowrap;
         overflow: hidden;
      }

      p:first-child {
         line-height: 24px;
         letter-spacing: 0.15px;
      }

      p:not(:first-child) {
         font-size: 12px;
         line-height: 20px;
         letter-spacing: 0.4px;
         color: rgba(255, 255, 255, 0.7);
      }
   }

   .ip-address-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      .ip-address-value {
         display: flex;
         text-overflow: ellipsis;
         white-space: nowrap;
         overflow: hidden;
      }
   
      .magnifying-glass-icon {
         display: none;
      }
   }

   .mobile-item-country {
      display: flex;
      align-items: center;
      gap: 4px;

      p {
         font-size: 12px;
         line-height: 20px;
         letter-spacing: 0.4px;
         color: rgba(255, 255, 255, 0.7);
      }

      span {
         color: unset;
         font-size: 18px;
      }
   }
   
   .status-icon {
      min-width: 40px;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      border-radius: 64px;

      @media (max-width: 1400px) {
         width: 32px;
         height: 32px;
      }
   }
   
   .healthy {
      background: rgba(23, 237, 195, 0.16);
   }
   
   .down {
      background: rgba(255, 167, 38, 0.3);
   }
   
   .blacklisted {
      background: rgba(240, 43, 79, 0.3);
   }

   .default {
      background: rgba(255, 255, 255, 0.3);

      path {
         fill: white;
      }
   }

   .disabled {
      background: rgba(255, 255, 255, 0.08);

      path {
         fill: rgba(255, 255, 255, 0.3);
      }
   }
   
   .line {
      margin: 16px 0 0 0;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.06);
   }
   
   .chip-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5%;
   }
   
   .item-actions {
      display: none;
   }

   .name-wrapper,
   .edit-wrapper {
      margin-left: 10%;
   }

   .edit-wrapper {
      display: flex;
      align-items: center;

      button {
         width: 80px;
         height: 40px;
         margin-left: 10px;
      }
   }

   .edit-input input {
      padding: 8px 12px;
   }

   .name-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      min-width: 10px;
      min-height: 20px;

      & > p:first-child {
         max-width: 150px;
         overflow: hidden;
         text-overflow: ellipsis;
      }
   }

   .country-container {
      min-width: 60px;
      margin-left: 5%;

      p {
         font-size: 24px;
      }
   }
   
   .isp-container {
      min-width: 140px;
      margin-left: 2%;

      p {
         width: 140px;
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
      }
   }

   .last-scan-container {
      min-width: 170px;
      margin-left: 5%;
   }

   .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 4px;

      &:hover {
         background: rgba(255, 255, 255, 0.08);
         cursor: pointer;
      }
      
      &-pencil {
         display: none;
      }

      &-disabled {
         path {
            fill: rgba(255, 255, 255, 0.5);
         }
      }
   }

   .disabled-text {
      color: rgba(255, 255, 255, 0.5);
   }

   .disabled-chip {
      opacity: 0.38;
   }

   .mobile-chip-container {
      margin-top: 15px;
   }
}

@media (min-width: 1750px) {
   .ip-row-container {
      .chip-container {
         margin-left: 10%;
      }
   }
 }

.ip-address-tooltip-value {
   padding: 24px;
   display: flex;
   flex-direction: column;
   gap: 12px;

   & > div {
      display: flex;
      align-items: center;
      gap: 4px;

      & p {
         font-weight: 400;
         font-size: 14px;
         line-height: 20px;
         letter-spacing: 0.17px;
      }

      & > p:first-child {
         min-width: 110px;
         color: rgba(255, 255, 255, 0.70);
      }
   }

   .status-icon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      svg {
         width: 10px;
         height: 10px;
      }
   }
}

.mxrisk-speedometer-wrapper {
  position: relative;
  display: inline-block;

  .gauge-container {
    width: 72px;
    height: 72px;
    margin-bottom: -36px;

    & > .gauge .dial {
      stroke: #33374D;
      stroke-width: 20;
    }
    & > .gauge .value {
      stroke-dasharray: none;
      stroke-width: 20;
    }

    &-tiny {
      width: 44px;
      height: 44px;
      margin-bottom: -44px;
    }

    &-medium {
      width: 110px;
      height: 110px;
      margin-bottom: -110px;
    }

    &-large {
      width: 155px;
      height: 155px;
      margin-bottom: -150px;
    }

    &-giant {
      width: 230px;
      height: 230px;
      margin-bottom: -150px;
    }
  }

  .speedometer-hand {
    position: absolute;
    top: calc(50% - (45px / 2));
    left: calc(50% - (14px / 2));
    transform-origin: 50% 70%;
    transform: rotateZ(-80deg);
    animation-delay: 500ms;

    &-tiny {
      top: calc(50% - 5px);
      left: calc(50% - 5px);
      transform-origin: 50% 80%;
      transform: rotateZ(-75deg);
    }

    &-medium {
      top: calc(50% - 10px);
      left: calc(50% - 14px);
      transform-origin: 50% 80%;
      transform: rotateZ(-75deg);
    }

    &-large {
      left: calc(50% - 14px);
      transform-origin: 50% 84%;
    }
  }
}
.container {
  display: flex;
  flex-direction: column;
  padding-top: 40px;

  .crop-button {
    align-self: flex-end;
    width: 120px;
    height: 40px;
  }
}
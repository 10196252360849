.create-account-wrapper {
  position: fixed;
  width: 100vw;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  background: #17EDC3;

  & > div {
     display: flex;
     align-items: center;
     gap: 12px;

     p {
        color: rgba(36, 42, 51, 0.92);
        font-weight: 500;
        line-height: 24px;
     }

     button {
        height: 40px;

        &:first-child {
           width: 180px;
           background: #242A33EB;
           color: white;
        }

        &:last-child {
           width: 69px;
           border: 2px solid #242A334D;
           color: #000000DE;
        }
     }
  }
}

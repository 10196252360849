.results2-wrapper {
   display: flex;
   background-color: #141623;
   padding-bottom: 120px;

   .auth-logo {
      cursor: pointer;
   }

   .sidebar {
      position: fixed;
      width: 256px;
      height: 100vh;
      padding: 24px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #0D1124;
      text-align: center;
      border-right: 1px solid rgba(255, 255, 255, 0.06);
      overflow-y: auto;
      padding-bottom: 100px;

      &-no-user {
         margin-top: 48px;
      }

      @media (max-width: 1250px) {
         display: none;
      }
   }

   .line {
      height: 1px;
      margin-top: 16px;
      background-color: rgba(255, 255, 255, 0.06);
   }

   .menu {
      .sidebar-item {
         display: flex;
         align-items: center;
         justify-content: space-between;
         padding: 14px 24px;
         cursor: pointer;
         width: calc(100% + 48px);
         margin-left: -24px;
         fill-opacity: 0.56;

         & > div:first-child {
            display: flex;
            align-items: center;

            & > p {
               text-align: left;
               width: 120px;
               margin-left: 16px;
               font-weight: 500;
               line-height: 24px;
               text-overflow: ellipsis;
               white-space: nowrap;
               overflow: hidden;
            } 
         } 
      }

      .active-item {
         background-color: #141623;
         color: #17EDC3;
         fill-opacity: 1;
      }

      .error-counter {
         display: flex;
         align-items: center;
         justify-content: center;
         width: 20px;
         height: 20px;
         border-radius: 50%;
         background-color: #F02B4F;
         color: white;
         font-size: 11px;
      }
   }

   .email-for-container {
      .result-at {
         display: flex;
         align-items: center;
         text-align: center;
         justify-content: center;
         margin-top: 2px;

         p {
            margin-left: 2px;
            color: rgba(255, 255, 255, 0.5);
            font-size: 12px;
            letter-spacing: 0.4px;
         }
      }

      & > p {
         font-size: 14px;
         font-weight: 500;
         line-height: 20px;
      }
   }

   .results-content {
      display: flex;
      min-height: 100vh;
      flex-direction: column;
      flex-grow: 1;
      padding-left: 256px;

      @media (max-width: 1250px) {
         padding-left: unset;
      }

      @media (max-width: 1000px) {
         padding-bottom: 328px;
      }
   }

   .create-account-and-speedometers {
      padding-top: 88px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #0D1124;

      &-no-account {
         padding-top: 54px;
      }

      @media (max-width: 1250px) {
         padding-top: unset;
      }

      @media (max-width: 1000px) {
         background: unset;
      }
   }

   .speedometer-container {
      display: flex;
      justify-content: center;
      gap: 24px;
      padding: 48px;
      padding-top: 24px;

      &-authorized {
         background: unset;
      }

      @media (max-width: 1000px) {
         flex-direction: column;
         align-items: center;
         gap: 24px;
         padding: 0 16px;
      }
   }

   .group-results-wrapper {
      padding: 48px;

      @media (max-width: 600px) {
         padding: 48px 16px;
      }
   }

   .group-results-header {
      display: flex;
      align-items: center;
      margin-bottom: 17px;

      &-label {
         margin-left: 16px;

         & > p:first-child {
            font-weight: 500;
            font-size: 24px;
            line-height: 34px;
            letter-spacing: -0.5px;
         }
      }

      &-description {
         p {
            color:rgba(255, 255, 255, 0.7);
         }
      }
   }

   .group-results-section-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
   }

   .group-status-mark {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
      height: 40px;
      margin-right: 16px;
      border-radius: 64px;
      background: rgba(23, 237, 195, 0.16);
      
      &.default {
         background: none;
         border: 1px solid rgba(255, 255, 255, 0.23);
      }
      
      @media (max-width: 600px) {
         display: none;
      }
   }

   .loading-placeholder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      & > p:nth-child(2) {
         margin-top: 24px;
         margin-bottom: 8px;
         font-weight: 500;
         font-size: 24px;
         line-height: 34px;
         letter-spacing: -0.5px;
      }

      & > p:last-child {
         text-align: center;
         max-width:  450px;
         letter-spacing: 0.15px;
         color: rgba(255, 255, 255, 0.5);
         line-height: 24px;
      }

      span {
         color: rgba(23, 237, 195, 1);
      }
   }

   .error-placeholder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      & > p:nth-child(2) {
         margin-top: 24px;
         margin-bottom: 8px;
         font-weight: 500;
         font-size: 24px;
         line-height: 34px;
         letter-spacing: -0.5px;
      }

      & > p:nth-child(3) {
         text-align: center;
         max-width:  450px;
         letter-spacing: 0.15px;
         color: rgba(255, 255, 255, 0.5);
         line-height: 24px;
      }

      button {
         width: 96px;
         height: 40px;
         margin-top: 8px;
      }
   }

   .share-wrapper {
      margin-top: 21px;
      margin-bottom: 29px;

      @media (max-width: 1250px) {
         margin-bottom: unset;
      }
   }

   .header {
      position: fixed;
      width: calc(100% - 256px);
      z-index: 1;
      background-color: #141623;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 24px;

      & > div {
         display: flex;
         align-items: center;
      }

      @media (max-width: 1250px) {
         width: 100%;
         position: relative;
      }
   }

   .back-wrapper {
      display: flex;
      align-items: center;
      gap: 11px;
      cursor: pointer;

      p {
         font-size: 14px;
         line-height: 143%;
         letter-spacing: 0.17px;
         color: rgba(255, 255, 255, 0.5);
      }

      @media (max-width: 1250px) {
         align-self: flex-start;
      }
   }

   .score-circle-wrapper {
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 1250px) {
         margin-top: -8px;
      }
   }

   .circle-content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 33px 24px 37px;
   }

   .mobile-report-hisotry-select {
      border: 1px solid rgba(255, 255, 255, 0.23);
   }

   .print-header,
   .print-info,
   .print-view-report,
   .print-footer {
      display: none;
   }

   .honeypot-results,
   .blacklist-matrix-wrapper {
      padding-left: 58px;
      padding-right: 32px;
      margin-top: 44px;

      &.no-paddings {
         padding: unset;
         margin-top: 16px;
      }

      @media (max-width: 600px) {
         padding-left: unset;
         padding-right: unset;
      }
   }

   .honeypot-results {
      .honeypot-results-item-column {
         display: flex;
         align-items: center;
      }

      .honeypot-results-item-text {
         color: rgba(255, 255, 255, 0.70);
         font-size: 14px;
         line-height: 20px;
         letter-spacing: 0.17px;
         text-overflow: ellipsis;
         white-space: nowrap;
         overflow: hidden;
      }

      .honeypot-results-item-chip {
         display: flex;
         justify-content: end;

         & > div:first-child {
            margin-right: 3px;
            justify-content: end;
         }
      }
   }

   @media (max-width: 1000px) {
      position: relative;
      width: 100%;
      min-height: 100vh;
   }
}

@media print {
   .results2-wrapper {
      footer {
         display: none;
      }

      .header,
      .back-wrapper {
         display: none;
      }
   }
}

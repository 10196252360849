.insert-html-modal-wrapper {
  width: 600px;
  padding-top: 24px;

  .confirm-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 40px;

    & > button {
       width: 76px;
       height: 40px;
    }
  }

   @media (max-width: 700px) {
    width: 300px;
   }
}
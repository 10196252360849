.subscription-and-usage-wrapper {
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  padding: 48px;

  .title {
    margin-bottom: 32px;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: -0.5px;

    @media (max-width: 500px) {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }

  .subscription-and-usage-content {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.11) 100%), #090D23;
    border-radius: 10px;
  }

  .plan-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: rgba(23, 237, 195, 0.16);
    color: #17EDC3;
    border-radius: 64px;

    &-disabled {
      background: rgba(255, 255, 255, 0.12);
      color: rgba(255, 255, 255, 0.56);
    }
  }

  .plan-info {
    display: flex;
    align-items: center;
    gap: 16px;

    .icon-wrapper {
      width: 56px;
      height: 56px;

      svg {
        scale: 1.3;
      }
    }
  }

  .plan-name-wrapper {
    & > p:first-child {
      margin-bottom: 2px;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.25px;
    }

    & > div {
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.17px;

      & > p:first-child,
      & > p:last-child {
        color: rgba(255, 255, 255, 0.7);
      }

      & > p:nth-child(2) {
        color: rgba(255, 255, 255, 0.3);
      }
    }
  }

  .plan-header-buttons {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .manage-plan-button {
    width: 118px;
    height: 40px;
  }

  .upgrade-button {
    width: 88px;
    height: 40px;
  }

  .plan-body {
    padding: 34px 32px;
  }

  .plan-section {
    display: flex;
  }

  .plan-section-name {
    min-width: 33%;
    height: 44px;
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .plan-section-name-info {
    .plan-section-name-title {
      font-weight: 500;
      line-height: 24px;

      &-disabled {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    & > p:last-child {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.17px;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .vertical-line {
    width: 1px;
    height: 44px;
    background-color: rgba(255, 255, 255, 0.06);
  }

  .progress-bars-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 32px;
    gap: 36px 0;

    .vertical-line {
      margin: 0 32px;
    }
  }

  .progress-bar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 9px;

    & > p:first-child {
      max-width: 190px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.1px;
      color: rgba(255, 255, 255, 0.7);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    & > div {
      display: flex;
      align-items: center;
      gap: 8px;

      & > p:first-child {
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: rgba(255, 255, 255, 0.5);
      }

      & > p:last-child {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.1px;
      }
    }
  }

  .progress-bar {
    width: 275px;
    height: 6px;
    border-radius: 23px;
    background: rgba(23, 237, 195, 0.16);

    &-disabled {
      background: rgba(255, 255, 255, 0.12);
    }

    div {
      height: 6px;
      background: #17EDC3;
      border-radius: 23px;
    }
  }

  .line {
    height: 1px;
    margin: 32px 0;
    background-color: rgba(255, 255, 255, 0.06);
  }

  .frequancy-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;

    p {
      font-size: 14px;
      letter-spacing: 0.4px;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  @media (max-width: 500px) {
    padding: 38px 16px;
  }
}
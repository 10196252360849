.email-item-row-container {
   &:hover {
      background: rgba(0, 0, 0, 0.3);
      cursor: pointer;

      .item-actions {
         position: absolute;
         right: 70px;
         display: flex;
         gap: 4px;
      }
   }

   .email-item-row {
      display: flex;
      align-items: center;
      width: 90%;
      padding-left: 24px;
      padding-top: 16px;

      .subject {
         display: flex;
         align-items: center;
         width: 200px;
         gap: 16px;

         p {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
         }

         .list-icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 40px;
            height: 40px;
            background: rgba(23, 237, 195, 0.16);
            border-radius: 64px;
         }
      }

      .mx-risk {
         display: flex;
         align-items: center;
         min-width: 150px;
         margin-left: 10%;
         gap: 16px;
      }

      .readability-container {
         margin-left: 5%;
         min-width: 100px;
      }

      .sentiment-container {
         min-width: 100px;
         margin-left: 5%;
      }

      .check-date-container {
         margin-left: 5%;
      }
   }
   
   .line {
      margin: 16px 0 0 0;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.06);
   }
   
   .item-actions {
      display: none;
   }

   .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 4px;

      &:hover {
         background: rgba(255, 255, 255, 0.08);
         cursor: pointer;
      }
   }

   .speedometer-container {
      margin-bottom: -25px;
   }

   .mobile-row {
      display: flex;
      justify-content: space-between;
      padding: 16px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #0A0E23;
      border-radius: 12px;
   }

   .mobile-item-info {
      & > p:first-child {
         font-size: 16px;
         line-height: 24px;
         letter-spacing: 0.15px;
      }

      & > p:nth-child(2) {
         font-size: 12px;
         line-height: 20px;
         letter-spacing: 0.4px;
         color: rgba(255, 255, 255, 0.7);
      }

      & > p:nth-child(3),
      span,
      & > div {
         font-size: 12px;
         line-height: 20px;
         letter-spacing: 0.4px;
      }

      span {
         color: rgba(255, 255, 255, 0.7);
      }

      & > div {
         display: flex;
         align-items: center;
         gap: 8px;

         svg {
            width: 14px;
            height: 14px;
         }
      }
   }

   .speedometer-and-info {
      display: flex;
      gap: 16px;
   }
}
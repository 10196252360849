button.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  text-transform: none;
}

.start-icon {
  position: absolute;
  left: 6px;
  top: 6px;
}

button.text {
  font-size: 14px;
  width: unset;
}
html, body, div, span, applet, object, iframe,
p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, img, ins, kbd, q, s, samp,
small, strike, tt, var,
b, u, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* reset styles ends */

* {
	box-sizing: border-box;
}

body {
	font-family: 'Roboto', sans-serif;
	color: white;
	background-color: #141623;
	-webkit-print-color-adjust:exact !important;
  print-color-adjust:exact !important;
}

textarea:focus, input:focus {
	color: white;
}

input, select, textarea{
	color: white;
}

a {
	color: inherit;
	text-decoration: none; /* no underline */
 }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	 border: 1px solid #141623;
   -webkit-box-shadow: 0 0 0 1000px #141623 inset !important;
	 -webkit-text-fill-color: white;
	 caret-color: white;
	 transition: background-color 5000s ease-in-out 0s;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	  -webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		  -moz-user-select: none; /* Old versions of Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
				 user-select: none; /* Non-prefixed version, currently
											  supported by Chrome, Edge, Opera and Firefox */
 }

 .scroll::-webkit-scrollbar-track {
	background-color: transparent;
 }
 .scroll::-webkit-scrollbar {
	width: 6px;
	background-color: transparent;
 }
 .scroll::-webkit-scrollbar-thumb {
	background: rgba(255, 255, 255, 0.6);
	border-radius: 4px;
 }

 *:focus-visible {
	outline: none;
 }

 // footer styles
 footer {
	z-index: 2;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: space-between;
	bottom: 0px;
	width: 100vw;
	padding: 15px 160px;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), #0288D1;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: rgba(255, 255, 255, 0.7);

	& > div {
		 display: flex;
		 align-items: center;

		 &:first-child {
				gap: 24px;
		 }
	}

	.rights {
		 font-weight: 400;
		 letter-spacing: 0.17px;
		 color: rgba(255, 255, 255, 0.5);
	}

	.social-container {
		 display: flex;
		 align-items: center;
		 gap: 22px;
	}

	@media (max-width: 1250px) {
		padding: 15px 40px;
 	}

	 @media (max-width: 1000px) {
		position: absolute;
		width: 100%;
		padding: 40px 16px;
		flex-direction: column;
		align-items: start;

		& > div:first-child {
			flex-direction: column;
			align-items: start;
			gap: 16px;
	 }

	 a {
		font-weight: 500;
		font-size: 13px;
		line-height: 22px;
		letter-spacing: 0.46px;
	 }

	 .rights {
			font-size: 14px;
			margin-bottom: 12px;
		}

		.social-container {
			margin-top: 36px;
			gap: 28px;

			.MuiIconButton-root {
				padding: 0;
			}
	 }
 	}
}

.MuiFormControlLabel-label.Mui-disabled {
	color: rgba(255, 255, 255, 0.5) !important;
}

.loading-item {
	height: 10px;
	background: linear-gradient(296.1deg, #434760 40%, rgba(67, 71, 96, 0.49) 60%, #434760 100%);
	border-radius: 17px;
	animation-duration: 10s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: loadingGreyBg;
	animation-timing-function: linear;
}

.MuiDataGrid-root {
	border: none !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus {
	outline: none !important;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
	background-color: transparent;
 }
.MuiDataGrid-virtualScroller::-webkit-scrollbar {
	width: 6px;
	background-color: transparent;
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
	background: rgba(255, 255, 255, 0.6);
	border-radius: 4px;
}

div.MuiPickersPopper-paper {
	background: var(--dark-background-paper-elevation-20, linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), #050A23);

	.MuiChip-root {
		border: unset;
	}

	.MuiDialogActions-root {
		border-top: 1px solid rgba(255, 255, 255, 0.12);
	}
}

.MuiMenuItem-root {
	min-width: 150px;
}

.rotate-365 {
	animation: rotation 2s infinite linear;
	-webkit-animation: rotation 2s infinite linear;
}

@keyframes rotation {
	from {
			transform: rotate(0deg);
	}
	to {
			transform: rotate(359deg);
	}
}

@media print {
	html, body {
		 width: 215.9mm;
		 height: 279.4mm;
		 background: white;
	}

	.pagebreak {
		clear: both;
		page-break-before: auto;
		page-break-after: auto;
		page-break-inside: auto;
		break-inside: avoid !important;
		-webkit-column-break-inside: avoid !important;
	}
}

@page {
	size: letter;
	margin: 0;
}

@-webkit-keyframes loadingGreyBg {
  0%{ background-position: -400px 0; }
  100% { background-position: 400px 0; }
}

@-moz-keyframes loadingGreyBg {
  0%{ background-position: -400px 0; }
  100% { background-position: 400px 0; }
}

@keyframes loadingGreyBg {
  0%{ background-position: -400px 0; }
  100% { background-position: 400px 0; }
}


#cup-of-tea_to {
	animation: cup-of-tea_to__to 1200ms linear infinite normal forwards
}

@keyframes cup-of-tea_to__to {
	0% {
		transform: translate(18px,6.75px);
		animation-timing-function: cubic-bezier(0.42,0,0.58,1)
	}
	41.666667% {
		transform: translate(25px,-8.25px);
		animation-timing-function: step-end
	}
	58.333333% {
		transform: translate(10px,23.75px);
		animation-timing-function: cubic-bezier(0.42,0,0.58,1)
	}
	100% {
		transform: translate(18px,6.75px)
	}
}

#cup-of-tea_tr {
	animation: cup-of-tea_tr__tr 1200ms linear infinite normal forwards
}

@keyframes cup-of-tea_tr__tr {
	0% {
		transform: translate(16.000268px,18px) rotate(9.598598deg);
		animation-timing-function: cubic-bezier(0.42,0,0.58,1)
	}
	50% {
		transform: translate(16.000268px,18px) rotate(-6.897971deg);
		animation-timing-function: cubic-bezier(0.42,0,0.58,1)
	}
	100% {
		transform: translate(16.000268px,18px) rotate(9.598598deg)
	}
}

.menu-item {
   gap: 12px;

   div {
      display: flex;
      align-items: center;
      gap: 12px;
   }

   p {
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;
   }

   .status-swich {
      margin-left: 20px;
   }
}
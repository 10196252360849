.share-report-modal-wrapper {
  width: 552px;
  margin-top: 24px;

  input {
    padding: 16px 12px;
  }

  .link-to-share-wrapper p {
    margin-bottom: 8px;
    letter-spacing: 0.15px;
  }

  .line {
    border: 1px solid rgba(255, 255, 255, 0.06);
    height: 1px;
    margin: 24px 0;
  }

  .copy-button-wrapper {
    position: relative;

    .copy-button {
      position: absolute;
      top: 8px;
      right: 12px;
      display: flex;
      align-items: center;
      gap: 8px;
      width: 126px;
      height: 40px;
    }

    .copy-icon {
      margin-top: -5px;
      position: relative;
      left: unset;
      right: unset;
    }

    .link-input input {
      width: calc(100% - 150px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .email-wrapper {
    p {
      margin-bottom: 12px;
      letter-spacing: 0.15px;
    }
  }

  .email-form {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .send-button {
      align-self: flex-end;
      display: flex;
      align-items: center;
      gap: 8px;
      width: 89px;
      height: 40px;
    }

    .send-icon {
      margin-top: -5px;
      position: relative;
      left: unset;
      right: unset;
    }
  }

  .switch-container {
    margin-bottom: 24px;
  }

	@media (max-width: 600px) {
    width: 300px;
  }
}
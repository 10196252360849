.results-frame-wrapper {
   height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   width: 100%;
   padding-top: 24px;
   background: linear-gradient(180deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0.09) 100%), #0B0F24;

   .header {
      padding-bottom: 40px;
   }

   .circle {
      margin-top: 10px;
   }

   .report-wrapper {
      flex: 1;
      width: 100%;
      padding: 40px 32px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), #050A23;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
   }

   .speedometer-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
   }

   .email-for-container {
      text-align: center;

      .result-at {
         display: flex;
         align-items: center;
         text-align: center;
         justify-content: center;
         margin-top: 8px;

         p {
            margin-left: 2px;
            color: rgba(255, 255, 255, 0.5);
            font-size: 12px;
            letter-spacing: 0.4px;
         }
      }

      & > p {
         font-size: 14px;
         font-weight: 500;
         line-height: 20px;
      }
   }

   .results-content {
      display: flex;
      min-height: 100vh;
      flex-direction: column;
      flex-grow: 1;
      padding-left: 256px;
   }

   .report-button {
      width: 196px;
      margin: 0 auto;
      margin-top: 40px;
   }

   .speedometer {
      width: calc(25% - 24px);
   }
}

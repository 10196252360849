.test-ended-stats {
  padding: 24px 32px 32px;
  width: 331px;
  box-sizing: border-box;
  border-right: 1px solid #FFFFFF0F;

  &-text {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  &-list {
    list-style-type: none;
    margin-bottom: 35px;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      &-name {
        display: flex;
        align-items: center;

        div {
          width: 8px;
          height: 8px;
          border-radius: 100%;
          box-sizing: border-box;
          margin-right: 10px;
        }

        p {
          color: #FFFFFFB2;
          font-size: 14px;
        } 
      }

      &-percent {
        font-size: 14px;
      }
    }
  }

  &-last-scan {
    font-size: 12px;
    color: #FFFFFFB2;
  }
}
.profile-wrapper {
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  padding: 48px;

  .title {
    margin-bottom: 32px;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: -0.5px;

    @media (max-width: 500px) {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }

  .form {
    padding: 32px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
    border-radius: 12px;
  }

  .default-avatar,
  .avatar-image {
    width: 88px;
    height: 88px;
    border-radius: 64px;
  }

  .default-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #48C6EF 0%, #6F86D6 100%);

    p {
      font-size: 32px;
      line-height: 46px;
      color: #141623;
    }
  }

  .avatar-input {
    display: none;
  }

  .upload-buttons {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .upload-photo-button,
  .remove-photo-button {
    width: 117px;
    height: 30px;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.46px;
    box-shadow:  none;
  }

  .avatar-wrapper {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 492px;
    padding: 32px 0;

    @media (max-width: 1000px) {
      width: unset;
    }
  }

  .name-inputs {
    display: flex;
    gap: 16px;

    & > div {
      width: 238px;
    }

    @media (max-width: 1000px) {
      flex-direction: column;

      & > div {
        width: 100%;
      }
    }
  }

  .select {
    height: 56px;
  }

  .select-value {
    p:first-child {
       font-size: 12px;
       line-height: 12px;
       letter-spacing: 0.15px;
       color: rgba(255, 255, 255, 0.7);
       margin-bottom: 3px;
    }
  }

  .save-button {
    width: 138px;
    height: 40px;
  }

  @media (max-width: 500px) {
    padding: 38px 16px;
  }
}
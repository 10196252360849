.add-or-edit-user-modal-wrapper {
  width: 600px;
  padding-top: 16px;

  .form {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .name-inputs {
    display: flex;
    gap: 12px;

    & > div {
      width: 50%;
    }
  }

  .confirm-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 40px;

    & > button {
       width: 76px;
       height: 40px;
    }
  }

  .role-input-wrapper {
    display: flex;
    position: relative;

    .MuiFormControl-root {
      width: 100%;
      height: 56px;
    }

    .role-select {
      height: 56px;

      div {
        font-size: unset;
        line-height: unset;
      }
    }
  }

  .role-question-button {
    z-index: 2;
    position: absolute;
    right: 40px;
    top: 7px;
  }

  .role-tooltip-description {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 10px 32px 10px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.17px;

    span {
      font-weight: 600; 
    }
  }
}
.email-verification-wrapper {
   max-width: 600px;
   padding: 48px 80px 56px;
   text-align: center;

   & > p {
      margin: 24px 0;
      font-size: 24px;
      line-height: 34px;
      font-weight: 500;
   }

   .text {
      color: rgba(255, 255, 255, 0.7);
      font-size: 16px;
      line-height: 24px;
   }

   .buttons {
      display: flex;
      justify-content: space-between;
      gap: 16px;

      & button {
         height: 40px;
      }
   }

   .error-message {
      display: flex;
      align-items: center;
      background-color: #F02B4F;
      margin-top: 24px;
      padding: 8px 16px;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      border-radius: 4px;

      p {
         width: 318px;
      }
   }

   .logout {
      text-align: center;
      margin: 40px auto 0;
      font-size: 14px;
   }
}
.header-wrapper {
   .mobile-header {
      justify-content: space-between;
      padding: 8px 16px;

      .MuiIconButton-root {
         padding: unset;
      }
   }

   .mobile-header-opened {
      border-bottom: 1px solid transparent;

      padding: 8px 30px 8px 16px;
   }

   .mobile-header-closed {
      border-bottom: 1px solid rgba(255, 255, 255, 0.06);
   }

   .mobile-logo-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;
   }

   .auth-menu {
      display: flex;
      align-items: center;

      & > p:first-child {
         margin-right: 12px;
      }
   }

   .mobile-sidebar {
      .line {
         height: 1px;
         margin-left: 16px;
         width: calc(100% - 32px);
         background-color: rgba(255, 255, 255, 0.06);
      }
   }

   header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 8px 24px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #0D1124;
   }
}

.mobile-sidebar-opened {
   position: fixed;
   width: 100vw;
   height: 100vh;
   z-index: 1000;
   background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #0D1124;
}
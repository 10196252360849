.users-wrapper {
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  padding: 48px;
  flex: 1;

  .title {
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: -0.5px;

    @media (max-width: 500px) {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .add-user-button {
    width: 117px;
    height: 40px;
  }

  .button-icon {
    position: relative;
    top: unset;
    left: unset;
    height: 20px;
    margin-right: 4px;
  }

  .table-head {
    display: flex;
    align-items: center;
    padding: 6px 0;

    .name-head {
      padding-left: 80px;
      width: 30%;
    }

    .email-head {
       min-width: 300px;
       margin-left: 10%;
    }

    .role-head {
       margin-left: 5%;
    }

    p {
       font-weight: 500;
       font-size: 14px;
       line-height: 24px;
       letter-spacing: 0.17px;
       color: rgba(255, 255, 255, 0.5);
    }
  }

  .users-list {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
    border-radius: 12px;
    margin: 32px 0;
  }

  .table-head-line {
    height: 1px;
    background: rgba(255, 255, 255, 0.04);
  }

  @media (max-width: 500px) {
    padding: 38px 16px;
  }

  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    gap: 24px;
 
    &-title {
       font-weight: 500;
       font-size: 24px;
       line-height: 34px;
       letter-spacing: -0.5px;
    }
  }
}

.add-or-edit-user-modal {
  overflow: visible;
}
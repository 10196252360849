.deliverability-test-form {
  &-email-select-wrapper {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 12px;

    .MuiInputBase-root {
      height: 56px;
      width: 400px;
    }
  }

  &-subject-input-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .input-filled{
      flex: 1;
    }

    .MuiInputBase-root {
      width: 100%;
    }

    .import-template-button {
      color: #FFFFFFB2;
      width: 137px;
      height: 30px;
      padding: 0;
      margin-left: 5px;
      font-size: 13px;
  
      .import-icon {
        position: static;
        color: #FFFFFFB2;
        margin-right: 4px;
      }
    }
  }

  .ck-editor-error {
    border: 1px solid #F02B4F;
  }

  .input-error-text {
    color: #F02B4F;
    margin: 4px 14px 24px;
  }

  &-toggle {
    margin-bottom: 12px;
  }

  &-frequency-select-wrapper {
    .MuiInputBase-root {
      height: 56px;
      width: 400px;
    }
  }

  .no-active-email-accounts-text {
    width: 450px;
    color: rgba(255, 255, 255, 0.7);
  }
}

.import-template-menu-wrapper {
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    align-items: center;
    width: 300px;
    gap: 16px;
    padding: 6px 24px;

    &:hover {
      cursor: pointer;
      background-color: rgb(27, 31, 50);
    }

    p {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

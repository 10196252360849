.notification-presets-wrapper {
  max-width: 1300px;
  padding: 48px;

  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    gap: 16px;

    button {
      width: 130px;
      height: 40px;
    }

    @media (max-width: 600px) {
      margin-bottom: 24px;

      button {
        min-width: 40px;
        width: 40px;
  
        .button-icon {
          margin-right: 0;
        }
      }
    }
  }

  .title {
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: -0.5px;

    @media (max-width: 600px) {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }

  .notification-table {
    padding-top: 22px;
    padding-bottom: 32px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
    border-radius: 12px;

    &-head {
      display: flex;
      align-items: center;
      padding: 0 10% 6px;

      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.17px;
        color: rgba(255, 255, 255, 0.5);
      }

      &-name {
        width: 30%;
      }

      &-email {
        width: 30%;
      }

      &-notification {
        width: 30%;
      }

      &-monitors {
        width: 10%;
      }
    }

    .line {
      height: 1px;
      background-color: rgba(255, 255, 255, 0.06);
    }
  }

  .button-icon {
    position: relative;
    top: unset;
    left: unset;
    height: 20px;
    margin-right: 4px;
  }

  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    height: calc(100vh - 389px);
    margin-top: 33px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
    border-radius: 12px;

    .title {
       font-weight: 500;
       font-size: 24px;
       line-height: 34px;
       text-align: center;
       letter-spacing: -0.5px;
    }

    .add-preset-button {
       width: 130px;
       height: 40px;
    }
  }

  .mobile-notification-presets-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  @media (max-width: 600px) {
    padding: 38px 16px;
  }
}
.deliverability-test-bar {
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  .bar-info {
    display: flex;
    align-items: center;
  }

  .bar-wrapper {
    width: 100%;

    p {
      text-align: center;
      margin-bottom: 10px;
    }

    .bar {
      width: 100%;
    }
  }

}
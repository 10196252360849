.email-account-row-container {
   &:hover {
      background: rgba(0, 0, 0, 0.3);
      cursor: pointer;
   }

   .email-item-row {
      display: flex;
      align-items: center;
      width: 90%;
      padding-left: 24px;
      padding-top: 16px;

      .email {
         display: flex;
         align-items: center;
         width: 250px;
         gap: 16px;

         .avatar-placeholder {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 40px;
            height: 40px;
            border-radius: 4px;
            background: #0288D1;
         }

         p {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
         }
      }

      .from-name {
         display: flex;
         align-items: center;
         min-width: 200px;
         margin-left: 10%;
         gap: 16px;
         
         p {
            max-width: 200px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
         }
      }

      .email-provider {
         margin-left: 5%;
         width: 130px;
         text-transform: capitalize;
         white-space: nowrap;
         text-overflow: ellipsis;
         overflow: hidden;
      }

      .mx-score {
         min-width: 140px;
         margin-left: 5%;

         &-add {
            display: flex;
            align-items: center;
            gap: 12px;

            &:hover {
               p {
                  color: white;
               }
            }

            canvas ~ p {
               text-align: center;
               font-size: 24px;
               font-weight: 300;
               line-height: 20px;
            }

            p {
               color: rgba(255, 255, 255, 0.50);
               font-size: 14px;
               line-height: 20px;
               letter-spacing: 0.17px;  
            }
         }
      }

      .status {
         margin-left: 5%;
      }
   }
   
   .line {
      margin: 16px 0 0 0;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.06);
   }


   .mobile-row {
      display: flex;
      justify-content: space-between;
      padding: 16px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #0A0E23;
      border-radius: 12px;
   }

   .mobile-item-info {
      & > p:first-child {
         font-size: 16px;
         line-height: 24px;
         letter-spacing: 0.15px;
      }

      & > p:nth-child(2) {
         font-size: 12px;
         line-height: 20px;
         letter-spacing: 0.4px;
         color: rgba(255, 255, 255, 0.7);
      }

      & > p:nth-child(3),
      span,
      & > div {
         font-size: 12px;
         line-height: 20px;
         letter-spacing: 0.4px;
      }

      span {
         color: rgba(255, 255, 255, 0.7);
      }

      & > div {
         display: flex;
         align-items: center;
         gap: 8px;

         svg {
            width: 14px;
            height: 14px;
         }
      }
   }

   .speedometer-and-info {
      display: flex;
      gap: 16px;
   }

   .chip-container {
      display: flex;
      align-items: center;
      gap: 7px;
    
      & > div:first-child {
        height: 24px;
        letter-spacing: 0.16px;
      }
   }

   .chip-archived {
      color: rgba(255, 255, 255, 0.50);
      border-color: rgba(255, 255, 255, 0.26);
   }

   .email-account-menu-wrapper {
      position: absolute;
      right: 24px;
   }

   &-archived {
      background: rgba(0, 0, 0, 0.3);

      .email-item-row {
         .email {
            color: rgba(255, 255, 255, 0.50);
   
            .avatar-placeholder {
               background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
            }
         }

         .from-name,
         .email-provider {
            color: #FFFFFF80;
         }
      }
   }
}

.hoc-scan-modal-wrapper {
  width: 600px;
  margin-top: 30px;

  input {
    padding: 16px 12px;
  }

  .copy-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    @media (max-width: 700px) {
      flex-direction: column;
     }
  }

  .email-wrapper {
    width: 100%;
    margin-left: 40px;

    p {
      margin-bottom: 8px;
    }

    @media (max-width: 700px) {
      margin-top: 24px;
      margin-left: unset;
     }
  }

  .copy-email-wrapper {
    position: relative;

    .copy-button {
      position: absolute;
      top: 8px;
      right: 12px;
      display: flex;
      align-items: center;
      gap: 8px;
      width: 89px;
      height: 40px;
    }

    .copy-icon {
      margin-top: -5px;
      position: relative;
      left: unset;
      right: unset;
    }

    .link-input input {
      width: calc(100% - 120px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .line {
    border: 1px solid rgba(255, 255, 255, 0.06);
    height: 1px;
    margin-bottom: 32px;
  }

  .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.25px;
  }

  .show-report-wrapper {
    display: flex;
    align-items: center;

    & > div {
      margin-left: 32px;

      p {
        margin-bottom: 16px;
      }

      button {
        width: 141px;
        height: 40px;
      }

      @media (max-width: 700px) {
        margin-left: unset;
        margin-top: 24px;

        button {
          margin: auto;
        }
       }
    }

    @media (max-width: 700px) {
      flex-direction: column;
     }
  }

  .loader {
    display: block;
    margin: 125px auto;
  }

  @media (max-width: 700px) {
    width: 300px;
    text-align: center;
  }
}
.email-result-wrapper {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
  border-radius: 12px;

  .email-result-description {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 40px 0px;
    overflow: hidden;

    @media (max-width: 1300px) {
      justify-content: start;
      flex-direction: column;
      gap: 24px;
      padding: 40px 16px 24px;
    }
  }

  .email-result-speedometer-container {
    display: inline-block;
  }

  .email-result-speedometer {
    display: flex;
    position: relative;
  }

  .speedometer-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 85px;

    p:first-child {
      font-weight: 500;
      font-size: 24px;
      line-height: 34px;
      letter-spacing: -0.5px;
    }

    p:last-child {
      margin-top: -10px;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.25px;
      color: #798795;
    }
  }

  .description-placeholder-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .email-result-footer {
    padding: 32px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #0A0E23;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    @media (max-width: 600px) {
      padding: 24px 16px;
    }
  }

  .description-content {
    font-family: 'Courier Prime', monospace;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.5);

    .table-head {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
    }

    .table-body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-top: 20px;
    }

    .table-body-item {
      display: flex;
      align-items: center;
    }

    .karma {
      display: flex;
      align-items: center;
      width: 82px;
    }

    .rule {
      width: 160px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .description {
      width: 350px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .footer-items {
    display: flex;
    justify-content: space-around;
    gap: 60px;

    @media (max-width: 1300px) {
      flex-wrap: wrap;
      gap: 16px 0;

      &-loading {
        gap: 16px;
      }
    }
  }

  .footer-item {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.15px;
      color: rgba(255, 255, 255, 0.7);

      @media (max-width: 600px) {
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0.4px;
      }
    }

    &-text {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.25px;

      @media (max-width: 600px) {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.17px;
      }
    }

    &-content {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    @media (max-width: 1300px) {
      width: 33%;
    }

    @media (max-width: 500px) {
      width: 45%;
    }
  }

  .description-wrapper {
    @media (max-width: 1300px) {
      align-self: flex-start;
    }
  }

  .mobile-result-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .mobile-result-item {
    display: flex;
    flex-direction: column;
    gap: 12px;

    p {
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: 800px) {
        max-width: 300px;
      }

      @media (max-width: 500px) {
        max-width: 200px;
      }
    }
  }

  .mobile-result-title {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
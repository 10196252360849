.monitor-list-modal-wrapper {
  width: 600px;
  padding-top: 17px;

  .line {
    height: 1px;
    background-color: rgba(255, 255, 255, 0.06);
    width: calc(100% + 48px);
    margin-left: -24px;
  }

  .monitor-table-head {
    display: flex;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 6px;

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.17px;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .monitor-table-body {
    .monitor-table-row {
      display: flex;
      align-items: center;
      padding: 12px 0;
      overflow: hidden;
      text-overflow: ellipsis;

      &-disabled {
        color: rgba(255, 255, 255, 0.5);

        .name > div {
          background: rgba(255, 255, 255, 0.12);
          color: rgba(255, 255, 255, 0.3);
        }
      }

      p {
        line-height: 24px;
        letter-spacing: 0.15px;
      }
    }

    .name {
      display: flex;
      align-items: center;
      gap: 16px;

      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 40px;
        min-height: 40px;
        background: rgba(23, 237, 195, 0.16);
        color: #17EDC3;
        border-radius: 64px;
      }
    }

    .type p,
    .status p {
      text-transform: capitalize;
    }
  }

  .monitor-table-head .name,
  .monitor-table-row .name {
    width: 50%;
  }

  .monitor-table-head .type,
  .monitor-table-row .type {
    width: 35%;
  }

  .monitor-table-head .status,
  .monitor-table-row .status {
    width: 15%;
  }

  .loader {
    display: block;
    margin: 125px auto;
  }
}
.menu-wrapper {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 40px;
   height: 40px;
   border-radius: 4px;
}

.menu-item {
   gap: 12px;

   p {
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;
   }

   .status-swich {
      margin-left: 20px;
   }
}

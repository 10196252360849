.pricing-plans-wrapper {
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  padding: 48px;

  .title {
    margin-bottom: 16px;
    text-align: center;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: -0.5px;

    @media (max-width: 500px) {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }

  .subtitle {
    margin-bottom: 32px;
    text-align: center;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(255, 255, 255, 0.5);

    @media (max-width: 500px) {
      font-weight: 500;
      font-size: 14px;
      line-height: 28px;
    }
  }

  .tabs {
    margin-bottom: 48px;
  }

  .pricing-plans {
    display: flex;
    align-items: top;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
  }

  .pricing-plan {
    height: fit-content;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #0A0E23;
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 12px;
  }

  .pricing-plan-title {
    padding: 32px;

    & > div > p:first-child {
      font-weight: 500;
      font-size: 24px;
      line-height: 34px;
      letter-spacing: -0.5px;
    }

    & > p:nth-child(2) {
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.17px;
      color: rgba(255, 255, 255, 0.7);
    }

    & > p:last-child {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }

    @media (max-width: 500px) {
      padding: 24px;
    }
  }

  .pricing-plan-features {
    padding: 32px;
    padding-bottom: 16px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), #060D2E;

    & > p:first-child {
      margin-bottom: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.15px;
    }

    & > p:nth-child(2) {
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.17px;
      color: rgba(255, 255, 255, 0.7);
    }

    @media (max-width: 500px) {
      padding: 24px;
    }
  }

  .feature-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
  }

  .feature {
    display: flex;
    gap: 16px;

    svg {
      margin-top: 3px;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      letter-spacing: 0.15px;
    }
  }

  .line {
    height: 1px;
    margin: 24px 0;
    background: rgba(255, 255, 255, 0.06);
  }

  .active-button,
  .upgrade-button,
  .contact-button {
    width: 294px;
    height: 56px;
    margin: 0 auto;
  }

  .button-icon {
    position: relative;
    top: unset;
    left: unset;
    height: 20px;
    margin-right: 8px;
  }

  .most-popular-pricing-plan-title {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 4px;
  }

  .pricing-plan-popular {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #0A0E23;
    border-image: linear-gradient(#17EDC3, #0091FF) 50;
    border-width: 3px;
    border-style: solid;
    border-radius: 6px;

    .pricing-plan-title {
      background: rgba(23, 237, 195, 0.08);
    }

    .pricing-plan-features {
      background: rgba(23, 237, 195, 0.16);
    }
  }

  .action-button-wrapper {
    height: 76px;
  }

  .frequancy-wrapper {
    margin-top: 5px;

    p {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  @media (max-width: 500px) {
    padding: 38px 16px;
  }
}
.add-ips-modal-wrapper {
  width: 600px;
  padding-top: 24px;

  .confirm-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 40px;

    & > button {
       width: 76px;
       height: 40px;
    }
  }

  .general-error-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 32px;
  }

  .general-error-wrapper {
    display: flex;
    align-items: center;
    padding: 14px 16px;
    gap: 12px;
    background-color: #F02B4F;
    border-radius: 4px;
  
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;
    }
   }

   .inputs {
    display: flex;
    flex-direction: column;
    gap: 8px;
   }

   @media (max-width: 700px) {
    width: 300px;
   }
}
.email-template-wrapper {
  display: flex;

  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #0A0E23;
  border-radius: 12px;

  .email-template-sidebar {
    width: 136px;
    border-right: 1px solid rgba(255, 255, 255, 0.06);

    .logo {
      display: flex;
      align-items: center;
      padding: 16px;
      gap: 8px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.06);

      p {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.4px;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .item-list {
      padding: 12px 8px;

      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 120px;
        height: 24px;
        border-radius: 80px;
        padding: 8px 10px;

        &.first-item-list {
          background: linear-gradient(0deg, #1D2133, #1D2133), #0A0E23;
        }

        & > div:first-child {
          display: flex;
          align-items: center;
          gap: 6px;

          & > div {
            background: #484D62;
            border-radius: 14px;
          }

          & > div:first-child {
            width: 8px;
            height: 8px;
          }

          & > div:last-child {
            width: 24px;
            height: 4px;
          }
        }

        .last-selected-item-list {
          width: 8px;
          height: 4px;
          background: #484D62;
          border-radius: 14px;
        }
      }
    }
  }

  .email-container {
    width: 80%;
    padding: 40px 32px 64px;

    &-iframe {
      width: 100%;
    }

    .email-header {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 16px;
      padding-bottom: 29px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.06);

      &-item {
        display: flex;
        align-items: center;
  
        & > p:first-child {
          width: 75px;
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: 0.4px;
          color: rgba(255, 255, 255, 0.5);
        }
      }

      &-item-value {
        p {
          line-height: 24px;
          letter-spacing: 0.15px;
        }
      }
    }

    .email-text {
      display: flex;
      flex-wrap: wrap;
      gap: 16px 8px;
      padding-top: 32px;
      line-height: 24px;
      letter-spacing: 0.15px;

      p {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    @media (max-width: 900px) {
      width: 100%;
      padding: 16px;
    }
  }

  .alert {
    margin-bottom: 32px;
  }

  .email-body-iframe-wrapper {
    position: relative;
    width: 100%;
  }

  .email-body-iframe {
    width: 100%;
    background: white;
    border: 4px solid #009688;
    border-radius: 12px;
    padding: 32px;
  }

  .vertical-viewport-icon {
    position: absolute;
    display: block;
    left: calc(50% - 32px);
    bottom: -10px;
    cursor: row-resize;
    transform: unset !important;
  }

  .mxr-highlight {
    background-color: rgba(23, 237, 195, 0.5);
  }
}
.deliverability-test-wrapper {
   display: flex;
   flex-direction: column;
   flex: 1;
   padding: 48px;

   .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 34px;
      gap: 16px;

      p {
         font-weight: 700;
         font-size: 32px;
         line-height: 44px;
         letter-spacing: -0.5px;

         @media (max-width: 500px) {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
         }
      }

      button {
         width: 110px;
         height: 40px;
      }
   }

   .button-icon {
      position: relative;
      top: unset;
      left: unset;
      height: 20px;
      margin-right: 8px;
   }

   .deliverability-tests {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
   }

   @media (max-width: 500px) {
      padding: 46px 16px;
   }
}
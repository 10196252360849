.auth-menu-wrapper {
  .auth-menu {
    display: flex;
    align-items: center;
    padding: 6px 0;
    gap: 16px;

    & > p:first-child {
       margin-right: 12px;
    }
 }

  .line {
    margin: 12px 0;
  }
}

.settings-menu-item,
.profile-menu-item {
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: 150px;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;

  &-nested {
    justify-content: space-between;
  }

  &-active {
    background-color: rgba(255, 255, 255, 0.08) !important;
  }

  svg {
    color: rgba(255, 255, 255, 0.56);
  }
  
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.17px;
  }
}

.settings-menu-item {
  min-width: 200px;
}

.profile-menu-item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.profile-menu-line {
  height: 1px;
  margin: 12px 24px;
  background-color: rgba(255, 255, 255, 0.06);
}

.profile-menu-user-data {
  display: flex;
  align-items: center;
  padding: 6px 16px;
  gap: 16px;
}

.default-avatar,
.avatar-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.default-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #48C6EF 0%, #6F86D6 100%);

  p {
    font-size: 14px;
    font-weight: 400;
    color: #141623;
    line-height: 20px;
    letter-spacing: 0.14px;
  }
}

.default-workspace {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #667EEA 0%, #764BA2 100%);
  color: #141623;
  border-radius: 8px;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.17px;
    text-transform: uppercase;
  }
}

.menu-name-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .full-name {
    width: 170px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.17px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
  }

  .active-account {
    width: 170px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    line-height: 14.4px;
    letter-spacing: 0.4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
  }
}

.switch-workspace {
  margin: 6px 16px;
  color: rgba(255, 255, 255, 0.70);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
}

.switch-workspace-accounts-list {
  max-height: 156px;
  overflow: auto;
}

.workspace-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 0 16px;
  cursor: pointer;
}

.workspace-item {
  display: flex;
  align-items: center;
  gap: 16px;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.17px;
  }
}

.share-with-friends-wrapper {
  display: flex;
  align-items: center;
  border-radius: 8px;
  gap: 12px;
  padding: 6px 12px;
  margin: 0 12px;
  background: rgba(23, 237, 195, 0.16);

  p {
    width: 130px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.17px;
  }
}
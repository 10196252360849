.folder-menu {
  height: 250px;

  .MuiPaper-root::-webkit-scrollbar-track {
    background-color: transparent;
   }
   .MuiPaper-root::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
   }
   .MuiPaper-root::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 4px;
   }
}

.folder-menu-item {
  gap: 12px;

  &-active {
    background-color: rgba(255, 255, 255, 0.08) !important;
  }
}
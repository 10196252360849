.terms-wrapper {
   padding: 48px 80px 56px;
   text-align: center;

   & > p {
      margin: 24px 0;
      font-size: 24px;
      line-height: 34px;
      font-weight: 500;
   }

   .text {
      color: rgba(255, 255, 255, 0.7);
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
   }

   .logout {
      text-align: center;
      margin: 40px auto 0;
      font-size: 14px;
   }

   .checkbox-container {
      display: flex;
      align-items: center;
      text-align: left;
      padding: 8px 20px 8px 8px;
      margin-bottom: 32px;
      border-radius: 8px;
      color: rgba(255, 255, 255, 0.6);
      background-color: #141623;
      cursor: pointer;

      & > p {
         width: 318px;
         margin-left: 10px;
         line-height: 24px;
         letter-spacing: 0.25px;
      }
   }
}
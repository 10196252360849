.reset-password-sent-wrapper {
   .text {
      margin-top: 24px;
      color: rgba(255, 255, 255, 0.7);
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
   }

   .resend {
      text-align: center;
      margin: 24px auto 0;
      font-size: 14px;
   }

   svg {
      margin-right: -15px;
   }
}
.history-modal-wrapper {
  .check-item-wrapper {
    border-bottom: 1px solid rgba(255, 255, 255, 0.04);
    padding: 16px 0 0 0;

    .check-item-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      margin-bottom: 16px;
    }
  
    .status-icon {
      width: 40px;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 64px;

      @media (max-width: 700px) {
        min-width: 32px;
        min-height: 32px;
      }
    }
      
    .healthy {
        background: rgba(23, 237, 195, 0.16);
    }
    
    .down {
        background: rgba(255, 167, 38, 0.3);
    }
    
    .blacklisted {
        background: rgba(240, 43, 79, 0.3);
    }
  
    .default {
      background: rgba(255, 255, 255, 0.3);
  
      path {
        fill: white;
      }
    }
  
    .check-item-label-wrapper {
      display: flex;
      align-items: center;
      gap: 16px;
  
      p {
        line-height: 24px;
        letter-spacing: 0.15px;
      }
    }
  
    .check-items {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .result-wrapper {
    margin-left: 60px;
    margin-right: 40px;

    .line {
      height: 1px;
      background: rgba(255, 255, 255, 0.04)
    }

    @media (max-width: 700px) {
      margin-left: 30px;
      margin-right: 20px;
    }
  }

  .result-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 0;
    background-color: #282c42;
    width: 100%;

    .tier-item {
     font-size: 14px;
     font-weight: 400;
     line-height: 20px;
     color: #FFFFFFB2;
     padding-left: 9px;
    }

    @media (max-width: 700px) {
      p {
        max-width: 150px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}

.according {
  background-color: #282c42;
  padding: 16px;
  border-radius: 8px;

  .MuiPaper-root {
   box-shadow: none;
   background-color: none;
   padding: 0;
  }

  .Mui-expanded {
    margin: 0 !important;
    background-color: #282c42
  }

  .MuiAccordionSummary-root {
    background-color: #282c42;
    padding: 0;
    height: 54px;
  }
}

.according-details {
  background-color: #282c42;
  padding: 0 !important;
  box-shadow: none;
  .wrapper {
    border-bottom: 1px solid #FFFFFF0F;

    &:last-child {
      border-bottom: none;
    }
  }
}

.chip-container {
  display: flex;
  align-items: center;
  gap: 7px;

  & > div:first-child {
    height: 24px;
    letter-spacing: 0.16px;
  }

  svg {
    color: rgba(255, 255, 255, 0.56);
  }
}
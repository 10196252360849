.placement-test-runs-history-select {
  width: 208px;

  .MuiSelect-select {
    padding: 12px 8px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
  }

  &-value {
    display: flex;
    align-items: center;
  
    p {
      margin-left: 8px;
    }
  }

  &-item {
    .label {
      margin-left: 12px;
    }
  }

  &-line {
    height: 1px;
    width: 100%;
    background-color:rgba(255, 255, 255, 0.06);
  }

  &-see-more {
    text-align: center;
    margin-top: 6px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.17px;
    color: #FFFFFF;
    cursor: pointer;
  }

  &-menu-item {
    display: flex;
    align-items: center;
    gap: 12px;

    &-score {
      width: 22px;
      height: 22px;
    }
  }
}

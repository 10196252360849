.template-folder {
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 532px;
   height: 94px;
   padding: 25px 24px;
   background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #0A0E23;
   border-radius: 12px;
   border: 1px solid transparent;

   &:hover {
      cursor: pointer;
      border: 1px solid rgba(255, 255, 255, 0.08);
      box-shadow: 0px 8px 38px 7px rgba(0, 0, 0, 0.12), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 10px 13px -6px rgba(0, 0, 0, 0.20);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), #050A23;
   }

   .folder-name-wrapper {
      display: flex;
      align-items: center;
      gap: 16px;

      & > div {
         display: flex;
         flex-direction: column;
         gap: 2px;

         .folder-name {
            max-width: 330px;
            font-weight: 500;
            line-height: 24px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
         }

         .folder-date {
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.4px;
            color: rgba(255, 255, 255, 0.7);
         }
      }
   }

   .folder-icon-wrapper {
      position: relative;
   }

   .folder-checks-count {
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 6.5px;
      background: rgba(255, 255, 255, 0.3);
      border-radius: 64px;
      color: rgba(0, 0, 0, 0.87);
   }
}
.real-time-monitor-wrapper {
   max-width: 1400px;
   flex: 1;
   padding: 48px 48px 0;

   .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      gap: 30px;

      p {
         font-weight: 700;
         font-size: 32px;
         line-height: 44px;
         letter-spacing: -0.5px;
         text-overflow: ellipsis;
         white-space: nowrap;
         overflow: hidden;

         @media (max-width: 500px) {
            max-width: 200px;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
         }
      }

      .add-ip-button {
         width: 100px;
         height: 40px;

         @media (max-width: 500px) {
            min-width: 40px;
            width: 40px;
         }
      }

      &-actions {
         display: flex;
         align-items: center;
         gap: 16px;
      }
   }

   .breadcrumbs {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      line-height: 143%;
      letter-spacing: 0.17px;
      color: rgba(255, 255, 255, 0.5);

      p:first-child {
         color: #009688;
      }
   }

   .ip-list {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 20px 0;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
      border-radius: 12px;
   }

   .mobile-table-pagination,
   .table-pagination {
      margin-left: auto;
   }

   .table-pagination {
      margin-top: 20px;
      margin-right: 24px;
   }

   .table-loader {
      position: absolute;
      left: 50%;
      top: 50%;
   }

   .mobile-ip-list {
      display: flex;
      flex-direction: column;
      gap: 16px;
   }

   .ip-list,
   .mobile-ip-list {
      margin: 32px 0;
   }

   .button-icon {
      position: relative;
      top: unset;
      left: unset;
      height: 20px;
      margin-right: 4px;

      @media (max-width: 500px) {
         margin-right: unset;
      }
   }

   .loader {
      display: block;
      margin: auto;
   }

   .empty-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;
      height: calc(100vh - 389px);
      margin-top: 33px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
      border-radius: 12px;

      .title {
         font-weight: 500;
         font-size: 24px;
         line-height: 34px;
         text-align: center;
         letter-spacing: -0.5px;
      }

      .add-ip-to-monitor-button {
         width: 173px;
         height: 40px;
      }
   }

   .table-head {
      display: flex;
      align-items: center;
      width: 90%;
      padding: 6px 0;
      padding-left: 24px;

      & div:first-child {
         display: flex;
         align-items: center;
         width: 35%;

         .ip-address-head {
            width: 160px;
         }

         .name-head {
            margin-left: 10%;
         }
      }

      .country-head {
         min-width: 60px;
         margin-left: 5%;
      }

      .isp-head {
         min-width: 140px;
         margin-left: 2%;
      }

      .last-scan-head {
         min-width: 170px;
         margin-left: 5%;
      }

      .status-head {
         margin-left: 5%;
      }

      p {
         font-weight: 500;
         font-size: 14px;
         line-height: 24px;
         letter-spacing: 0.17px;
         color: rgba(255, 255, 255, 0.5);
      }
   }

   .table-head-line {
      height: 1px;
      background: rgba(255, 255, 255, 0.04);
   }

   .name-wrapper {
      display: flex;
      align-items: center;
      width: 90%;
      gap: 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @media (max-width: 500px) {
         flex-direction: column;
         align-items: start;
      }
   }

   @media (max-width: 700px) {
      padding: 40px 16px;
   }
}

@media (min-width: 1750px) {
   .real-time-monitor-wrapper {
      .table-head {
         .status-head {
            margin-left: 10%;
         }
      }
   }
 }
.copy-email-modal-wrapper {
  width: 600px;
  padding-top: 30px;

  .description {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.17px;
    color: rgba(255, 255, 255, 0.7);
  }

  .copy-email-wrapper {
    position: relative;

    .copy-button {
      position: absolute;
      top: 8px;
      right: 12px;
      display: flex;
      align-items: center;
      gap: 8px;
      width: 89px;
      height: 40px;
    }

    .copy-icon {
      margin-top: -5px;
      position: relative;
      left: unset;
      right: unset;
    }

    .link-input input {
      padding: 16px 12px;
      width: calc(100% - 120px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  @media (max-width: 700px) {
    width: 300px;
  }
}
.deliverability-test-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 48px;
}

.deliverability-test-unauthorized-wrapper {
  .logo-wrapper {
    margin-top: 16px;
    text-align: center;
  }

  .deliverability-test-content-wrapper {
    max-width: calc(1000px + (48px * 2));
    margin: auto;
  }

  .deliverability-test-unauthorized-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 48px;

    & > p:first-child {
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      letter-spacing: -0.5px;
    }

    & > p:nth-child(2) {
      line-height: 24px;
      letter-spacing: 0.15px;
      color: rgba(23, 237, 195, 1);
    }

    .date {
      display: flex;
      align-items: center;
      gap: 4px;
      color: rgba(255, 255, 255, 0.5);

      p {
        font-size: 12px;
        line-height: 19.92px;
        letter-spacing: 0.4;
      }
    }
  }
}

.email-accounts-wrapper {
   max-width: 1400px;
   display: flex;
   flex-direction: column;
   flex: 1;
   padding: 48px;

   .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 34px;
      gap: 16px;

      & > div:first-child {
         display: flex;
         align-items: center;
         gap: 16px;
      }

      p {
         font-weight: 700;
         font-size: 32px;
         line-height: 44px;
         letter-spacing: -0.5px;

         @media (max-width: 500px) {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
         }
      }

      button {
         width: 150px;
         height: 40px;
      }
   }

   .button-icon {
      position: relative;
      top: unset;
      left: unset;
      height: 20px;
      margin-right: 8px;
   }

   .empty-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      padding-top: 32px;

      &-title {
         margin: 24px 0;
         font-weight: 500;
         font-size: 24px;
         line-height: 34px;
         letter-spacing: -0.5px;
      }

      button {
         width: 229px;
         height: 40px;
      }
   }

   .list {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 20px 0;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
      border-radius: 12px;
   }

   .table-head {
      display: flex;
      align-items: center;
      width: 90%;
      padding: 6px 0;
      padding-left: 24px;

      .email-address-head {
         min-width: 250px;
      }

      .from-name-head {
         min-width: 200px;
         margin-left: 10%;
      }

      .email-provider-head {
         min-width: 130px;
         margin-left: 5%;
      }

      .mx-score-head {
         min-width: 140px;
         margin-left: 5%;
      }

      .status-head {
         margin-left: 5%;
      }

      p {
         font-weight: 500;
         font-size: 14px;
         line-height: 24px;
         letter-spacing: 0.17px;
         color: rgba(255, 255, 255, 0.5);
      }
   }

   .table-head-line {
      height: 1px;
      background: rgba(255, 255, 255, 0.04);
   }

   .table-pagination {
      margin-left: auto;
      margin-top: 20px;
      margin-right: 24px;
   }

   .filter-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 4px 10px;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.12);
      cursor: pointer;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */

      &:hover,
      &-active {
         background: rgba(255, 255, 255, 0.3);
      }

      p {
         font-size: 13px;
         font-weight: 500;
         line-height: 22px;
         letter-spacing: 0.46px;
      }
   }

   @media (max-width: 500px) {
      padding: 46px 16px;
   }
}

.clear-filter-icon:hover {
   color: #17EDC3;
}
button.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  text-transform: none;
}

button.text {
  font-size: 14px;
  width: unset;
}
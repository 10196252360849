.email-deliverability-test-modal-wrapper {
  width: 600px;
  padding-top: 24px;

  .header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #FFFFFF0F;
    padding-bottom: 24px;
    margin-bottom: 24px;

    &-item {
      display: flex;
      padding: 8px 0px;
      align-items: center;

      p:first-child {
      flex-basis: 64px;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: #FFFFFF80
     }

      p:last-child {
       font-size: 16px;
       font-weight: 400;
       line-height: 24px;
       letter-spacing: 0.4px;
     }
    }
  }

  .body {
    padding-bottom: 24px;
  }

  .confirm-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 40px;

    button:first-child {
       width: 77px;
       height: 40px;
    }

    button:last-child {
      width: 63px;
      height: 40px;
   }
  }

  @media (max-width: 700px) {
    width: 300px
  }
}

.email-body-iframe-wrapper {
  position: relative;
  width: 100%;
}

.email-body-iframe {
  width: 100%;
  background: white;
  border-radius: 12px;
  padding: 32px;
}

.vertical-viewport-icon {
  position: absolute;
  display: block;
  left: calc(50% - 32px);
  bottom: -14px;
  cursor: row-resize;
  transform: unset !important;
}

.email-container {
  width: 100%;

  &-iframe {
    width: 100%;
  }

  .email-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    padding-bottom: 29px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);

    &-item {
      display: flex;
      align-items: center;

      & > p:first-child {
        width: 75px;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.4px;
        color: rgba(255, 255, 255, 0.5);
      }
    }

    &-item-value {
      p {
        line-height: 24px;
        letter-spacing: 0.15px;
      }
    }
  }

  .email-text {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 8px;
    padding-top: 32px;
    line-height: 24px;
    letter-spacing: 0.15px;

    p {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  @media (max-width: 900px) {
    width: 100%;
    padding: 16px;
  }
}

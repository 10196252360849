.save-template-modal-wrapper {
  width: 600px;
  padding-top: 24px;

  .select-value {
    p:first-child {
       font-size: 12px;
       line-height: 12px;
       letter-spacing: 0.15px;
       color: rgba(255, 255, 255, 0.7);
       margin-bottom: 3px;
    }
  }

  .select-item {
    display: flex;
    align-items: center;
  }

  .confirm-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 40px;

    button:first-child {
       width: 77px;
       height: 40px;
    }

    button:last-child {
      width: 63px;
      height: 40px;
   }
  }

  .inputs {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .loader {
    display: block;
    margin: 70px auto;
  }
}

//

.save-template-modal {
  overflow: visible;
}

.save-template-select-item {
  gap: 12px;

  p {
    line-height: 150%;
    letter-spacing: 0.15px;
  }
}

.save-template-line {
  height: 1px;
  margin: 4px 0;
  background: rgba(255, 255, 255, 0.06);
}

.add-folder-wrapper {
  padding-left: 8px;

  .add-folder-button {
    height: 36px;
  }

  .button-icon {
    position: relative;
    top: unset;
    left: unset;
    height: 20px;
    margin-right: 6px;
  }

  .add-folder-edit-mode {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 6px;
    padding-left: 8px;
  }

  .name-input {
    width: 360px;

    & > div {
       border: 1px solid #17EDC3;
    }

    input {
       padding-top: 8.5px;
       padding-bottom: 8.5px;
    }
 }

 .check-icon-button {
    width: 40px;
    height: 40px;
    background-color: rgba(23, 237, 195, 0.12);
    border-radius: 4px;

    &:hover {
      background-color: rgba(23, 237, 195, 0.2);
    }

    &-disabled {
      path {
          fill: rgba(255, 255, 255, 0.3);
      }
    }
  }
}
.email-infrastructure-wrapper {
   display: flex;
   flex-direction: column;
   flex: 1;
   padding: 48px;

   .title {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;
      margin-bottom: 34px;

      p {
         font-weight: 700;
         font-size: 32px;
         line-height: 44px;
         letter-spacing: -0.5px;

         @media (max-width: 500px) {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
         }
      }
   }

   .list-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
   }

   .table-pagination {
      margin-left: auto;
   }

   .email-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 24px;
      margin-bottom: 32px;

      @media (max-width: 1070px) {
         justify-content: center;
      }
   }

   .button-icon {
      position: relative;
      top: unset;
      left: unset;
      height: 20px;
      margin-right: 4px;
   }

   .load-more-btn {
      margin: 0 auto;
   }

   .loader {
      display: block;
      margin: auto;
   }

   .buttons {
      display: flex;
      align-items: center;
      gap: 16px;

      & > button {
         width: 147px;
         height: 40px;
      }
   }

   .mobile-buttons {
      display: flex;
      align-items: center;
      gap: 8px;

      button {
         min-width: 40px;
         width: 40px;
         height: 40px;

         .button-icon {
            margin-right: 0;
         }
      }
   }

   button.hoc-scan-button {
      width: 168px;
      padding-right: 0;

      .hoc-scan-button-content {
         display: flex;
         align-items: center;

         p {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
         }

         & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            margin-left: 35px;
            padding-left: 8px;
            padding-right: 10px;
            border-left: 1px solid rgba(23, 237, 195, 0.5);
         }
      }
   }

   .empty-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      padding-top: 32px;
   
      &-title {
         margin: 24px 0;
         font-weight: 500;
         font-size: 24px;
         line-height: 34px;
         letter-spacing: -0.5px;
      }
   
      button {
         width: 142px;
         height: 40px;
      }
   }

   @media (max-width: 500px) {
      padding: 38px 16px;
   }
}

.hoc-scan-history-menu-content {
   padding: 0px 12px;

   & > p:first-child {
      font-weight: 500;
      font-size: 16px;
   }

   & > p:last-child {
      font-size: 14px;
   }

   .line {
      width: calc(100% + 24px);
      height: 1px;
      margin: 8px 0;
      margin-left: -12px;
      background-color: rgba(255, 255, 255, 0.06);
   }

   .hoc-scan-history-list {
      display: flex;
      flex-direction: column;
      gap: 16px;
   }

   .hoc-scan-item {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 0 12px;
   }

   .label {
      p:last-child {
         font-size: 14px;
         line-height: 24px;
         letter-spacing: 0.17px;
         color: rgba(255, 255, 255, 0.7);
      }
   }

   .hoc-scan-see-more {
      cursor: pointer;
   }
}
.email-item {
   width: 345px;
   height: 300px;
   background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #0A0E23;
   border-radius: 12px;
   border: 1px solid transparent;
   cursor: pointer;

   & > div:first-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
   }

   &:hover {
      border: 1px solid rgba(255, 255, 255, 0.08);
      box-shadow: 0px 8px 38px 7px rgba(0, 0, 0, 0.12), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 10px 13px -6px rgba(0, 0, 0, 0.20);

      & > div:first-child {
         background: linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.11) 100%), #090D23;
      }

      .service-groups-container {
         background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), #050A23;
         border-bottom-left-radius: 12px;
         border-bottom-right-radius: 12px;
      }
   }

   &-default-cursor {
      cursor: default;
   }
}

.email-title-container {
   display: flex;
   align-items: center;
   width: 100%;
   gap: 16px;
   font-weight: 500;

   .waiting-text {
      font-size: 12px;
      line-height: 166%;
      letter-spacing: 0.4px;
      color: rgba(255, 255, 255, 0.5);
   }

   p:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
   }

   .email-monitor-title {
      width: 170px;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .monitor-name {
         font-weight: 500;
         font-size: 18px;
         line-height: 26px;
         letter-spacing: 0.25px;
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
      }

      .monitor-status {
         font-size: 12px;
         line-height: 166%;
         letter-spacing: 0.4px;
         color: rgba(255, 255, 255, 0.7);
      }
   }
}

.service-groups-container {
   padding: 20px 32px;

   .check-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      gap: 10px;
      font-size: 14px;
      line-height: 143%;
      letter-spacing: 0.17px;
   }

   .service-groups-disabled-state,
   .service-groups-waiting-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
   }

   .service-groups-disabled-state {
      height: 160px;
      padding: 38px 0;

      p {
         font-size: 14px;
         line-height: 143%;
         letter-spacing: 0.17px;
         color: rgba(255, 255, 255, 0.7);
      }
   }

   .service-groups-waiting-state {
      padding: 7px 0;

      & > p:first-child {
         line-height: 24px;
         letter-spacing: 0.15px;
      }

      .generated-email-container {
         text-align: center;

         p:first-child {
            margin-top: 8px;
            font-size: 12px;
            line-height: 166%;
            letter-spacing: 0.4px;
            color: rgba(255, 255, 255, 0.5);
         }

         p:last-child {
            font-size: 14px;
            line-height: 143%;
            text-align: center;
            letter-spacing: 0.17px;
            color: #17EDC3;
            cursor: pointer;
         }
      }
   }
}

.email-title {
   display: flex;
   align-items: center;

   .circle {
      width: 8px;
      height: 8px;
      margin-right: 11px;
      border-radius: 100%;
   
      &-success {
         background-color: #17EDC3;
      }
   
      &-warning {
         background-color: #FFA726;
      }
   
      &-error {
         background-color: #F02B4F;
      }
   }
}

.tests-pass-text {
   font-weight: 500;
   font-size: 14px;
   line-height: 20px;
   letter-spacing: 0.15px;

   &-warning {
      color: #FFA726;
   }

   &-error {
      color: #F02B4F;
   }
}

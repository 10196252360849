.authorized-wrapper {
   display: flex;
   background-color: #141623;

   .sidebar {
      z-index: 1;
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 256px;
      height: 100vh;
      padding: 24px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #0D1124;
      text-align: center;
      border-right: 1px solid rgba(255, 255, 255, 0.06);
      overflow-y: auto;
      padding-bottom: 70px;

      .line {
         height: 1px;
         background-color: rgba(255, 255, 255, 0.06);
      }
   }

   .footer-and-content-wrapper {
      position: relative;
      width: 100%;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
   }

   .content {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-left: 256px;
      padding-bottom: 70px;

      @media (max-width: 1000px) {
         padding-bottom: 328px;
      }

      @media (max-width: 700px) {
         margin-left: unset;
      }
   }
}

@media (max-width: 700px) {
   li.MuiMenuItem-root {
      min-height: 36px;
   }
}
.monitor-result-group-section {
  padding: 32px 24px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
  border-radius: 12px;

  &.no-paddings {
    padding: unset;         
  }

  .group-section-title {
    display: flex;
    justify-content: space-between;

    & > div:first-child {
       display: flex;
       align-items: center;
    }

    .align-items-center {
       align-items: center;
    }

    .chip {
       width: 100px;
       margin-top: 5px;

       @media (max-width: 600px) {
        width: 33px;
        height: 24px;
        margin-top: unset;

        span {
          padding: 0 6px;
        }

        path {
          fill-opacity: 1;
        }
      }
    }

    .group-status-mark-fail {
      background: rgba(240, 43, 79, 0.3);
    }
 }

 .sub-chip {
  @media (max-width: 600px) {
    width: 33px;
    height: 24px;
  
    span {
      padding: 0 6px;
    }
  
    path {
      fill-opacity: 1;
    }
  }
 }

 .blacklist-chip {
  width: 100px;

  @media (max-width: 1250px) {
    width: unset;
  }
 }

 .arrow {
  color: rgba(255, 255, 255, 0.56);
 }


  .group-section-text {
    p:first-child {
       font-weight: 500;
       font-size: 18px;
       line-height: 26px;
       letter-spacing: 0.25px;

       @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }

  .chip-and-arrow-wrapper {
    display: flex;
    align-items: center;
    width: 110px;
    justify-content: space-between;

    .chip-wrapper {
      width: 75px;
      display: flex;
      justify-content: flex-end;
    }

    @media (max-width: 600px) {
      width: unset;
    }
  }

  .description {
    word-break: break-all;
    padding-left: 58px;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: rgba(255, 255, 255, 0.7);

    @media (max-width: 600px) {
      margin-top: 28px;
      padding-left: unset;
    }
  }

  .rules {
    margin-top: 40px;
    padding-left: 66px;

    &-chip-wrapper {
      width: 110px;
    }

    @media (max-width: 600px) {
      padding-left: unset;

      &-chip-wrapper {
        width: unset
      }
    }
  }

  .rule-item {
    .rule-item-content {
       display: flex;
       align-items: center;
       justify-content: space-between;

       p {
        font-size: 14px;
        line-height: 143%;
        letter-spacing: 0.17px;
       }

       & > div:first-child {
        display: flex;
        align-items: center;

        p:first-child {
          width: 250px;
          color: rgba(255, 255, 255, 0.7);
        }

        p:nth-child(2) {
            width: 350px;
            color: rgba(255, 255, 255, 0.5);
        }

        @media (max-width: 1000px) {
          flex-direction: column;
          align-items: flex-start;

          p:first-child,
          p:nth-child(2) {
            width: 250px;
          }
        }

        @media (max-width: 600px) {
          flex-direction: column;
          align-items: flex-start;

          p:first-child,
          p:nth-child(2) {
            width: 150px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }

    & .line {
       margin: 14px 0;
    }

  }

  .spamassassin-chip-wrapper {
    width: 85px;

    @media (max-width: 600px) {
      width: 50px;
    }
  }

  .spamassassin-chip {
    width: 48px;
  }

  .more-info {
    display: flex;
    align-items: center;

    p {
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;
      letter-spacing: 0.46px;
      color: rgba(255, 255, 255, 0.7);
    }
  }
}

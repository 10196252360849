.add-or-edit-notification-preset-modal-wrapper {
  width: 600px;
  padding-top: 24px;

  .notification-preset-name-input {
    input {
      padding: 16px 12px;
    }
  }

  .notification-custom-settings {
    display: flex;
    flex-direction: column;
    margin-top: 17px;

    & > p:first-child {
      margin-bottom: 16px;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.25px;
    }

    .switches-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .emails-textarea > div:first-child {
    padding: 9px 12px;
  }

  .helper-text {
    margin-top: 3px;
    margin-left: 14px;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: rgba(255, 255, 255, 0.7);

    &-error {
      color: #F02B4F;
    }
  }

  .switch-label {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .comming-soon-label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 6.5px;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 64px;

    p {
      color: white;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.14px;
    }
  }

  .select-value {
    p:first-child {
       font-size: 12px;
       line-height: 12px;
       letter-spacing: 0.15px;
       color: rgba(255, 255, 255, 0.7);
       margin-bottom: 3px;
    }
  }

  .confirm-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 40px;

    & > button {
       width: 76px;
       height: 40px;
    }
  }

  .general-error-wrapper {
    display: flex;
    align-items: center;
    padding: 14px 16px;
    margin-bottom: 32px;
    gap: 12px;
    background-color: #F02B4F;
    border-radius: 4px;

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;
    }
  }
  
  .inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .line {
    width: calc(100% + 48px);
    height: 1px;
    margin-top: 24px;
    margin-left: -24px;
    background-color: rgba(255, 255, 255, 0.06);
  }

  @media (max-width: 700px) {
    width: 330px;
  }
}
.deliverability-test-details-inbox-report {
  margin-bottom: 90px;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    &-title {
      font-size: 24px;
      font-weight: 500;
    }

    &-history {
      display: flex;
      color: #FFFFFFB2;
      align-items: center;
      cursor: pointer;
      border: 0;
      background-color: unset;

      &-text {
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }

  &-content {
    width: 100%;
    height: 264px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0.09) 100%), #0B0F24;
    border-radius: 12px;
    padding: 32px;

    &-bar-list {
      list-style-type: none;
      display: flex;
      height: 100%;
      gap: 16px;
    }
  }
}
.input {
   border: 1px solid transparent;
   border-radius: 4px;
   color: white;
}

div.MuiInputBase-root {
   background: #141623;
   border: 1px solid transparent;
   border-bottom-left-radius: 4px;
   border-bottom-right-radius: 4px;

   &.Mui-readOnly:focus-within {
      border: 1px solid gray;
   }

   &:focus-within {
      border: 1px solid #17EDC3;
      background: #141623;  
   }

   &:hover {
      background: #141623;  
   }

   &.Mui-error {
      border: 1px solid #F02B4F;
   }
}

.MuiInputBase-input {
   border-radius: 4px;
}

div.MuiTextField-root {
   background-color: transparent;
} 

.MuiTextField-root.input {
   width: 100%;
}

.MuiTextField-root label.Mui-focused {
   color: #17EDC3;

   &:has(+ .Mui-readOnly) {
      color: gray;
   }
}

.MuiTextField-root label.Mui-error {
   color: #F02B4F;
}

.MuiTextField-root label.Mui-disabled {
   color: rgba(255, 255, 255, 0.35);
}

.MuiTextField-root label {
   color: rgba(255, 255, 255, 0.7);
}

.input-standard {
   .MuiTextField-root.input {
      border: none;
      border-bottom: 1px solid transparent;
      border-radius: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
   }
   
   .MuiTextField-root:focus-within {
      border: none;
      border-bottom: 1px solid #17EDC3;
   }
}

.multiline {
   .MuiInputBase-root {
      padding-top: 12px;
      padding-left: 12px;
   }
}

.multiline-with-label {
   .MuiInputBase-root {
      padding-top: 20px;
   }
}

.MuiInputBase-inputMultiline {
   &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.6);
      border-radius: 4px;
    }
}
.delete-modal-wrapper {
  margin: -10px -8px 0;

  .confirm-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 40px;

    & > button {
       width: 76px;
       height: 40px;
    }
  }
}

.delete-modal-title-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-top: 8px;

  .title-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 56px;
    height: 56px;
    background: rgba(240, 43, 79, 0.3);
    border-radius: 50%;
  }
}
.test-card {
  max-width: 532px;
  height: 330px;
  box-sizing: border-box;
  border-radius: 12px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #0A0E23;

  &-header {   
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
    
    &-icon-wrapper {
      position: relative;
      background-color: #17EDC329;
      width: 48px;
      height: 48px;
      box-sizing: border-box;
      border-radius: 100%;
      padding: 12px 0 0 12px;
    }

    &-refresh-icon {
      position: absolute;
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      background-color: #17EDC3;
      border-radius: 100%;
      padding: 0px 0 0 3px;
      bottom: -3px;
      right: -6px;
    }

    &-title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    &-subtitle {
      display: flex;
      align-items: center;

      &-text {
        font-size: 12;
        margin-right: 15px;
        color: #FFFFFF;
      }

      .MuiChip-root {
        padding: 6px;
        border: 0;
        height: 20px;

        span {
          font-size: 12px;
          font-weight: 500;
          padding: 0;
        }
      }
    }

    .MuiCardHeader-action {
      margin-top: 4px;

      button {
        color: #FFFFFF8F;
      }
    }
  }

  .MuiCardContent-root:last-child {
    padding-bottom: 0px;
  }

  &-content {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #0A0E23;
    display: flex;
    height: 232px;
    padding: 0;

    &-diagram {
      padding: 12px 40px 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 201px;
      box-sizing: border-box;

      .view-report-button {
        height: 40px;
        box-shadow: unset;
      }
    }

    &-cup-of-tea {
      width: 201px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

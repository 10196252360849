.signup-wrapper {
   padding: 48px 104px 56px;

   .title {
      margin-bottom: 32px;
      text-align: center;
      font-weight: 500;
      font-size: 24px;
      line-height: 34px;
   }
   
   .form {
      width: 336px;

      .password-input {
         margin-top: 12px;
      }

      .login-button {
         margin: 32px 0;
      }

      @media (max-width: 600px) {
         width: unset;
         padding: 0 24px;
      }
   }
   
   .services {
      text-align: center;
   
      & > p {
         font-size: 14px;
      }
   }
   
   .button-group button {
      margin-top: 12px;
      color: white;
   }
   
   button.google-button {
      background-color: #397AF3;
   
      &:hover {
         background-color: #397AF3;
      }
   }
   
   button.linkedin-button {
      background-color: #006BA7;
   
      &:hover {
         background-color: #006BA7;
      }
   }
   
   button.pitchbox-button {
      background-color: #000000;
      opacity: 0.5;
   
      &:hover {
         background-color: #000000;
      }
   }
   
   .line {
      height: 1px;
      margin-top: 32px;
      margin-bottom: 40px;
      background-color: rgba(255, 255, 255, 0.08);
   }
   
   .form-footer {
      button {
         margin: 0 auto;
      }
   }

   @media (max-width: 600px) {
      padding: 24px 0 28px;
   }
}
.report-row {
  height: 96px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
  
  &-cell {
    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 16px;
      width: 100%;
      height: 96px;
    }

    &:first-child {
      width: 292px;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }

    &:last-child {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    &-name-wrapper {
      display: flex;
      width: 100%;
      padding-left: 32px;
      height: 96px;
      align-items: center;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;

      p {
        font-size: 20px;
        font-weight: 500;
        margin-left: 15px;
      }
    }
  }
}

.report-collapse-row-cell {
  &-wrapper {
    padding: 0 16px 16px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.report-collapse-inner-table {
  &-row {
    height: 56px;
    background-color: #141623;
    border-radius: 12px;

    td {
      border-color: #FFFFFF0F;
    }

    &-name-cell {
      width: 276px;

      &-wrapper {
        display: flex;
        align-items: center;

        p {
          margin-left: 23px;
        }
      }
    }

    &:last-child {
      td {
        border: 0;
      }
    }
  }

  .empty-cell {
    width: 64px;
  }
}

.report-history-modal-wrapper {
  display: flex;
  flex-direction: column;
  width: 600px;

  .line {
    width: calc(100% + 48px);
    height: 1px;
    margin-top: 17px;
    margin-left: -24px;
    background-color:rgba(255, 255, 255, 0.06);
  }

  .items-wrapper {
    width: calc(100% + 48px);
    margin-left: -24px;
    height: 682px;
    padding: 0px 0 16px;
    overflow: auto,
  }

  .item {
    cursor: pointer;

    svg {
      display: none;
    }

    &:hover,
    &:active {
      background: rgba(0, 0, 0, 0.3);

      svg {
        display: block;
      }
    }

    & > div:first-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 29px 12px 48px;

      @media (max-width: 700px) {
        padding: 12px 16px;
       }
    }

    .line {
      width: calc(100%);
      margin-top: 0;
      margin-left: 0;
    }

    .circle-wrapper {
      display: flex;
      align-items: center;

      .label,
      .label-container {
        margin-left: 16px;
      }

      .label-container {
        display: flex;
        flex-direction: column;
        gap: 4px;

        p:last-child {
          color: rgba(255, 255, 255, 0.7);
          font-size: 14px;
          line-height: 24px;
        }
      }
    }

    svg {
      color: rgba(255, 255, 255, 0.56);
    }
  }

  .report-hisotry-modal-pagination {
    align-self: flex-end;
  }

  .icons {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  @media (max-width: 700px) {
    width: 300px;
   }
}
/* Basic CKEditor Dark Theme */
* {
  --ck-color-button-default-hover-background: #0EBE9B;
  --ck-color-button-on-hover-background: #0EBE9B;
  --ck-color-button-on-background: #0EBE9B;
  --ck-color-button-on-color: white;
  --ck-color-button-save: white;
  --ck-color-button-cancel: white;
  --ck-color-labeled-field-label-background: #141623;
  --ck-color-link-default: #1e90ff;
  --ck-color-base-text: white;
  --ck-color-text: white;
  --ck-color-base-border: rgba(255, 255, 255, 0.3);
  --ck-color-toolbar-border: rgba(255, 255, 255, 0.3);
}

/* Background */
div.ck-editor-wrapper {
  .ck.ck-editor,
  .ck.ck-content,
  .ck.ck-dropdown__panel,
  .ck-source-editing-area {
      background-color: #141623 !important;
      color: white;
      & .ck-focused {
        border-color: rgba(255, 255, 255, 0.3) !important;
      }
  }

  .ck.ck-content {
    min-height: 400px;
    padding: 12px 24px;
  }

  .ck-source-editing-area {
    min-height: 400px;
  }

  /* Toolbar */
  .ck.ck-toolbar {
    background-color: #141623 !important;
  }

  /* Buttons */
  .ck.ck-button,
  .ck.ck-list__item,
  .ck.ck-dropdown__button {
    color: rgba(255, 255, 255, 0.56);

    & .ck-on {
      color: #12EDC2 !important;
    }
  }

  .ck.ck-list__item:hover,
  .ck.ck-dropdown__button:hover {
    background-color: #141623 !important;
    color: #fff !important;
  }

  .ck.ck-button:active,
  .ck.ck-list__item:active,
  .ck.ck-dropdown__button:active {
    background-color: #555 !important;
    color: #fff !important;
  }

  /* Dropdown */
  .ck.ck-dropdown__panel {
    border: 1px solid #141623 !important;
  }

  /* Tooltips */
  .ck.ck-tooltip {
    background-color: #555 !important;
    color: #fff !important;
  }

  .ck.ck-tooltip__text {
    color: #fff !important;
  }

  /* Modals and overlays */
  .ck.ck-modal-background {
    background-color: #141623 !important;
  }

  .ck.ck-modal {
    background-color: #2c2c2c !important;
    color: white !important;
    border: 1px solid #141623 !important;
  }

  /* Dialog tabs and buttons */
  .ck.ck-tab {
    color: white !important;
  }

  .ck.ck-tab:hover {
    color: #fff !important;
  }

  .ck.ck-tab.ck-on {
    color: #fff !important;
    background-color: #555 !important;
  }

  .ck.ck-button.ck-off.ck-disabled,
  .ck.ck-button.ck-disabled:hover {
    color: #666 !important;
  }

  /* Editor content (adjust based on your content styling needs) */
  .ck-content {
    color: white !important;
  }

  .ck-content a {
    color: #1e90ff !important;
  }

  .ck-source-editing-area {
    textarea {
      background-color: #141623 !important;
      color: white;

      &:not([readonly]):focus {
        border-color: #12EDC2 !important;
      }
    }
  }

  .ck.ck-list {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), #081029 !important;

    .ck-button.ck-on,
    .ck-button.ck-on:hover,
    .ck-button.ck-on:active {
      background-color: #0EBE9B !important;
      border-color: #0EBE9B !important;
      color: white !important;
    }

    .ck-button:hover:not(.ck-disabled) {
      background-color: #12EDC2 !important;
    }
  }

  div.html-embed-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 2px;

    path {
      fill: rgba(255, 255, 255, 0.56) !important;
    }

    &:hover {
      background: #0EBE9B !important;
    }

    &:active {
      background: #0EBE9B !important;
    }

    &-disabled {
      path {
        fill: gray !important;
        opacity: 0.5;
      }

      &:hover,
      &:active {
        background: unset !important;
      }
    }
  }

  // error styles
  &-error {
    .ck.ck-content,
    .ck.ck-toolbar {
      border-color: #f44336 !important;
    }
  }

  // read-only styles
  &-read-only {
    .ck.ck-content {
      min-height: unset;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #0A0E23 !important;
      border-top: none;
    }
  }
}

.ck.ck-balloon-panel {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), #081029 !important;
}

.ck.ck-input {
  background: #141623 !important;

  &:focus {
    border-color: #12EDC2 !important;
    box-shadow: unset;
    color: white !important;
  }
}

.ck.ck-label {
  color: white !important;
}

.ck-dropdown__panel {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), #081029 !important;
}

.ck.ck-powered-by-balloon {
  display: none !important;
}

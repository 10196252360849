.notification-item-container {
   .notificaiton-row {
      position: relative;
      display: flex;
      align-items: center;
      padding: 16px 10%;

      &:hover {
         background: rgba(0, 0, 0, 0.3);

         .start-icon {
            display: block;
            position: static;
          }

         .item-actions {
            position: absolute;
            right: 70px;
            display: flex;
            cursor: pointer;
         }
      }

      .item-actions {
         display: none;
      }

      .start-icon {
        display: none;
      }
      

      &-name {
         display: flex;
         align-items: center;
         width: calc(30% + 56px);
         gap: 12px;
         margin-left: -56px;

         p {
            line-height: 24px;
            letter-spacing: 0.15px;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-left: 4px;
         }
      }

      &-email {
         width: 30%;

         p {
            width: 90%;
            font-weight: 400;
            font-size: 14px;
            line-height: 143%;
            letter-spacing: 0.17px;
            text-overflow: ellipsis;
            overflow: hidden; 
         }

         .dash {
            font-size: 14px;
            line-height: 143%;
            letter-spacing: 0.17px;
            color: rgba(255, 255, 255, 0.5);
         }
      }

      &-notification {
         display: flex;
         align-items: center;
         width: 30%;
         gap: 24px;
      }

      &-monitors {
         width: 10%;

         p {
            display: inline-block;
            font-weight: 500;
            font-size: 13px;
            line-height: 22px;
            letter-spacing: 0.46px;
            color: rgba(255, 255, 255, 0.7);
         }

         .monitors-count-wrapper {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 4px 8px;
            border-radius: 4px;

            &:hover {
               background: rgba(255, 255, 255, 0.08);
               cursor: pointer;
            }
         }
      }
   }

   .pb16 {
      padding-bottom: 16px;
   }
}

.notification-preset-bell-icon-container {
   display: flex;
   align-items: center;
   justify-content: center;
   min-width: 40px;
   height: 40px;
   background: rgba(23, 237, 195, 0.16);
   border-radius: 64px;
   color: #17EDC3;

   @media (max-width: 1000px) {
      min-width: 32px;
      height: 32px;
   }
}

.notification-preset-disabled-icon {
   path {
      fill: rgba(255, 255, 255, 0.3);
   };
}

.mobile-notification-item-container {
   display: flex;
   justify-content: space-between;
   padding: 16px;
   background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #0A0E23;
   border-radius: 12px;

   .mobile-notification-item-content {
      display: flex;
      gap: 16px;
   }

   .mobile-notification-item-info {
      p:first-child {
         line-height: 24px;
         letter-spacing: 0.15px;
      }

      p:not(:first-child) {
         font-size: 12px;
         line-height: 20px;
         letter-spacing: 0.4px;
         color: rgba(255, 255, 255, 0.7);
      }
   }

   .mobile-notification-types {
      display: flex;
      align-items: center;
      margin-top: 12px;
      gap: 16px;
   }
}
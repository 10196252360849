.speedometer {
  width: calc(25% - 24px);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.14) 100%), #050A23;
  box-shadow: 0px 10px 14px 1px rgba(0, 0, 0, 0.04);
  border-radius: 12px;

  & > div:first-child {
    display: flex;
    flex-direction: column;
    padding: 24px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0.09) 100%), #0B0F24;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.25px;
    color: rgba(255, 255, 255, 0.7);
    text-transform: capitalize;
  }

  .test-results {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 0px;

    & p:first-child {
      margin-bottom: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 157%;
      letter-spacing: 0.1px;
    }
  }

  .blur {
    width: 76px;
    height: 8px;
    margin-top: 13px;
    background: linear-gradient(270.62deg, #404457 6.51%, rgba(64, 68, 87, 0.4) 47.31%, #404457 96.61%);
    border-radius: 31px;
  }

  .guage {
    min-width: 150px;
    width: unset !important;
  }

  .loader {
    align-self: center;
    margin-top: 18px;
  }

  .label {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.25px;

    &-success {
       color: #17EDC3;
    }

    &-warning {
       color: #FFA726;
    }

    &-error {
       color: #F02B4F;
    }
 }

  @media (max-width: 1410px) {
    width: 206px;
  }

  @media (max-width: 1000px) {
    width: 343px;
  }

  @media (max-width: 600px) {
    width: 320px;
  }
}

@media print {
  .speedometer {
    width: 246px;
    background: #F5F5F5;
    border: 1px solid rgba(36, 42, 51, 0.08);

    & > div:first-child {
       background: white;
    }

    .name {
      color: rgba(36, 42, 51, 0.6);
    }

    .test-results {
      p:first-child {
        color: rgba(36, 42, 51, 0.6);
      }

      .label {
        color: rgba(36, 42, 51, 0.92);
      }
    }

    .guage {
      min-width: 206px !important;
      max-width: 206px !important;
      width: 206px !important;
    }

    .needle {
      path, circle {
        fill: rgba(89, 105, 146, 0.5);
      }
    }

    .arc {
      path {
        fill: #009688 !important;
      }
    }
  }
}
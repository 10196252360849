.dashboard-wrapper {
   display: flex;
   flex-direction: column;
   flex: 1;
   padding: 48px;
   max-width: 1184px;

   .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 34px;
      gap: 0 24px;

      & > p:first-child {
         font-weight: 700;
         font-size: 32px;
         line-height: 44px;
         letter-spacing: -0.5px;

         @media (max-width: 500px) {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
         }
      }
   }

   .period-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px 16px;

      & > div:first-child {
         display: flex;
         align-items: center;
         gap: 8px;

         p {
            color: rgba(255, 255, 255, 0.70);
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.4px;
         }
      }
   }

   .dashboard-select {
      width: 136px;
      height: 40px;
      background: rgba(255, 255, 255, 0.12);
  
      &:focus-within,
      &:hover {
        background: rgba(255, 255, 255, 0.15);
        border: 1px solid transparent;
      }
  
      & div {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.4px;
        color: white;
      }
    }

   .count-group,
   .graph-group,
   .list-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 24px;
   }

   .graph-group,
   .list-group {
      margin-top: 24px;
   }

   .item {
      display: flex;
      flex-direction: column;
      padding: 24px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #0A0E23;
      border: 1px solid transparent;
      border-radius: 12px;
 
      &:hover {
         background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), #050A23;
         border: 1px solid rgba(255, 255, 255, 0.08);
         box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
      }

      &-error {
         display: flex;
         flex-direction: column;
         align-items: center;
         gap: 24px;
         margin: auto;

         button {
            width: 100px;
            height: 40px;
         }
      }

      .loader {
         display: block;
         margin: auto;
      }
   }

   .count-item {
      min-width: calc((1184px / 4) - (24px * 3));
      flex: 1;
      height: 222px;
      padding: 24px;
      
      & > p {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.25px;
      }
   }

   .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      margin-bottom: 24px;
      background: rgba(23, 237, 195, 0.12);
      border-radius: 50%;
   }

   .count {
      display: flex;
      align-items: center;
      gap: 12px;
  
      & > p:first-child {
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
        letter-spacing: -0.5px;
      }
    }
  
    .count-chip {
      height: 24px;
      border-radius: 16px;
      background: rgba(23, 237, 195, 0.3);
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.16px;
      color: #17EDC3;
  
      svg {
        margin-left: 7px;
      }
  
      &-error {
        background: rgba(240, 43, 79, 0.3);
        color: #F02B4F;
      }
   }

   .items-count {
      display: flex;
      align-items: center;
      gap: 4px;

      p {
         font-family: Roboto;
         font-size: 12px;
         font-style: normal;
         font-weight: 400;
         line-height: 20px;
         letter-spacing: 0.4px;

         &:first-child {
            color: rgba(255, 255, 255, 0.70);
         }

         &:last-child {
            color: rgba(255, 255, 255, 0.5);
         }
      }
   }

   .graph-item,
   .email-deliverability-item,
   .email-accounts-item {
      min-width: calc((1184px / 2) - (24px * 1)) - 40px;
      flex: 1;

      @media (max-width: 900px) {
         min-width: unset;
         height: unset;
      }

      .graph-content,
      .email-deliverability-block-content {
         display: flex;
         flex-direction: column;
         flex: 1;

         & > div:first-child {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 24px;
            gap: 12px 24px;
   
            & > p:first-child {
               font-weight: 500;
               font-size: 18px;
               line-height: 26px;
               letter-spacing: 0.25px;
            }
   
            & > p:nth-child(2) {
               color: rgba(255, 255, 255, 0.50);
               text-align: right;
               font-size: 12px;
               line-height: 20px;
               letter-spacing: 0.4px;
            }
         }
      }
   }
 
   .graph-item {
      height: 300px;
   }

   .email-deliverability-item {
      padding-bottom: 10px;

      .inbox-report-statistic {
         display: flex;
         gap: 24px;
     
         &-score {
           position: relative;
           width: 288px;
           height: 159px;
           margin-top: -20px;
           margin-left: -36px;
           border-radius: 8px;
           box-sizing: border-box;
           display: flex;
           justify-content: center;
           padding-top: 6px;
     
           .gauge-container {
             & > .gauge .dial {
               stroke: #141623;
               stroke-width: 7;
               stroke-linecap: round;
             }
     
             & > .gauge .value {
               stroke-dasharray: none;
               stroke-width: 7;
               stroke-linecap: round;
             }
           }
     
           &-info {
             position: absolute;
             display: flex;
             flex-direction: column;
             align-items: center;
             top: 70px;
             left: 90px;
     
             &-percents {
               font-size: 28px;
               font-weight: 600;
               color: #17EDC3;
               line-height: 40px;
             }
     
             &-text {
               font-size: 14px;
               color: #FFFFFFB2;
               line-height: 22px;
             }
           }
         }
     
         &-item {
            font-size: 14px;

            &-text {
               width: 106px;
               color: rgba(255, 255, 255, 0.70);
               line-height: 20px;
               letter-spacing: 0.17px;
            }

            &-value {
               font-weight: 500;
               line-height: 22px;
               letter-spacing: 0.1px;
            }
         }
       }
   }

   .email-accounts-item {
      padding-bottom: 36px;
   }

   .graph-tooltip {
      padding: 4px 8px;
      background: rgba(20, 22, 35, 0.8);
      border-radius: 4px;
  
      & p {
        font-size: 10px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, 0.7);
      }
  
      & p:nth-child(2) {
        color: white;
      }
   }

   .indicators {
      display: flex;
      align-items: center;
      gap: 32px;
      flex-wrap: wrap;

      & > div {
         display: flex;
         align-items: center;
         gap: 16px;

         p {
            font-size: 14px;
         }

         p:nth-child(2) {
            color: rgba(255, 255, 255, 0.70);
            line-height: 20px;
            letter-spacing: 0.17px;
         }

         p:nth-child(3) {
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.1px;
         }
      }
   }

   .indicator {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: #17EDC3;

      &-error {
         background: #F02B4F;
      }
   }

   .percent-content-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
   }

   .percent-content {
      display: flex;
      flex-direction: column;
      gap: 16px 32px;
      flex-wrap: wrap;
      max-height: 212px;
  
      & > div {
       display: flex;
       align-items: center;
  
       & > p:nth-child(2) {
        width: 65px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.17px;
        color: rgba(255, 255, 255, 0.7);
       }
  
       & > p:last-child {
        margin-left: 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.1px;
       }
      }
    }
  
    .point {
      width: 8px;
      height: 8px;
      margin-right: 16px;
      border-radius: 50%;
    }
  
    .doughnut {
      width: 160px;
      height: 160px;
      position: relative;
    }
  
    .doughnut-content {
      text-align: center;
      margin-top: -115px;
  
      & > p:first-child {
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
        letter-spacing: -0.5px;
      }
  
      & > p:last-child {
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: rgba(255, 255, 255, 0.5);
      }
   }

   .list-item {
      height: 430px;
      overflow-y: auto;

      @media (max-width: 900px) {
         min-width: 100%;
      }
   }

   .list-content-wrapper {
      .dashboard-list-item {
         display: flex;
         align-items: center;
         justify-content: space-between;
         flex-wrap: wrap;
         gap: 12px 0;
         padding: 8px 0;

         .name-wrapper {
            display: flex;
            align-items: center;
            gap: 16px;

            p {
               width: 160px;
               font-size: 14px;
               line-height: 20px;
               letter-spacing: 0.17px;
               text-overflow: ellipsis;
               white-space: nowrap;
               overflow: hidden;
            }

            .from-email {
               width: 220px;
            }
         }

         &-icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            border-radius: 100%;
         }

         &-time {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.17px;
         }

         &-chip {
            width: 120px;
            text-align: right;

            & > div:first-child {
               height: 25px;
            }

            span {
               font-size: 13px;
               font-weight: 500;
               line-height: 18px;
               letter-spacing: 0.16px;
            }
         }
      }

      .divider {
         width: calc(100% + 48px);
         border-color: #FFFFFF0A;
         margin-left: -24px;
      }
   }

   .empty-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      gap: 16px;

      p {
         font-size: 14px;
         font-weight: 500;
         line-height: 20px;
         letter-spacing: 0.15px;
      }
   }

   .date-range-wrapper {
      &:hover {
         fieldset {
            border-color: rgba(255, 255, 255, 0.23);
         }
      }
   }

   .date-range-button {
      width: 120px;
   }

   .email-accounts-block-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 110px;
      font-size: 14px;
      text-align: center;

      &-icon {
         display: flex;
         align-items: center;
         justify-content: center;
         width: 48px;
         height: 48px;
         border-radius: 50%;
         
         &.active {
            background: rgba(23, 237, 195, 0.12);
            color: #17EDC3;
         }

         &.disconnected {
            background: rgba(255, 167, 38, 0.30);
            color: #FFA726;
         }

         &.archived {
            background: rgba(255, 255, 255, 0.12);
            color: rgba(255, 255, 255, 0.70);
         }
      }

      &-title {
         margin-top: 16px;
         margin-bottom: 8px;
         color: rgba(255, 255, 255, 0.70);
         line-height: 20px;
         letter-spacing: 0.17px;
      }

      &-count {
         font-weight: 500;
         line-height: 22px;
         letter-spacing: 0.1px;
      }
   }

   .email-accounts-block-score-wrapper {
      align-items: center;
      width: 120px;
      gap: 16px;

      &-average {
         width: 80px;
         text-align: center;
         color: rgba(255, 255, 255, 0.70);
         line-height: 20px;
         letter-spacing: 0.17px;
      }
   }

   @media (max-width: 500px) {
      padding: 38px 16px;
   }
}

.change-password-wrapper {
  max-width: 1300px;
  padding: 48px;

  .title {
    margin-bottom: 32px;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: -0.5px;

    @media (max-width: 500px) {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }

  .form {
    padding: 32px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
    border-radius: 12px;

    & > button {
      width: 138px;
      height: 40px;
    }

    & > button.reset-password-button {
      width: 170px;
      height: 40px;
    }
  }

  .inputs {
    display: flex;
    flex-direction: column;
    width: 492px;
    margin-bottom: 32px;
    gap: 16px;

    @media (max-width: 1000px) {
      width: unset;
    }
  }

  .text {
    margin-bottom: 32px;
  }

  @media (max-width: 500px) {
    padding: 38px 16px;
  }
}
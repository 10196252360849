.blacklist-matrix-wrapper {
  .tier-label,
  .tier-item-title {
     white-space: nowrap;
     font-size: 12px;
     line-height: 19.92px;
     letter-spacing: 0.4px;
  }

  .tier-label {
     transform: rotate(-90deg);
  }

  .tier-item-title {
     color: rgba(255, 255, 255, 0.50);
     overflow: hidden;
     text-overflow: ellipsis;

     &.failed {
        color: white;
     }
  }

  .blacklist-matrix-item-popover {
   padding: 16px 0;

   .blacklist-matrix-item-popover-title {
      padding: 6px 24px;
      margin-bottom: 8px;
      color: rgba(255, 255, 255, 0.70);
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.17px;
   }

   .blacklist-matrix-item-popover-list {
      max-height: 260px;
      overflow-y: auto;
   }

   .popover-ip-record {
      padding: 6px 24px;

      &:hover {
         background: rgba(0, 0, 0, 0.12);

         .popover-ip-record-actions {
            display: flex;
         }
      }

      .popover-ip-record-actions {
         display: none;
      }

      .popover-ip-record-title,
      .popover-ip-record-description {
         width: 130px;
         overflow: hidden;
         text-overflow: ellipsis;
      }

      .popover-ip-record-title {
         font-size: 14px;
         line-height: 20px;
         letter-spacing: 0.17px;
         overflow: hidden;
         text-overflow: ellipsis;
      }

      .popover-ip-record-description {
         color: rgba(255, 255, 255, 0.50);
         font-size: 12px;
         font-weight: 400;
         line-height: 20px;
         letter-spacing: 0.4px;
      }
   }
  }
}

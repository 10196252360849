.select {
  border: 1px solid rgba(255, 255, 255, 0.23);

  & div {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.7);
  }

  svg {
    color: rgba(255, 255, 255, 0.56);
  }

  fieldset {
    display: none;
  }

  &-filled {
    border: none;
    background-color: #141623;

    & div {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: white;
    }
  }

  .select-placeholder {
    padding: 7.5px 0;
    color: rgba(255, 255, 255, 0.7);
  }
}

.MuiMenu-paper.MuiPaper-root {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.14) 100%), #050A23;
  box-shadow: 0px 4px 18px 3px rgba(0, 0, 0, 0.12), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 6px 6px -3px rgba(0, 0, 0, 0.20);
  color: white;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.17px;

  .Mui-selected,
  .Mui-selected:hover,
  .Mui-selected:active,
  .Mui-selected:focus {
    background-color: #141623;
  }
}

.select-green-border {
  .MuiInputBase-root {
    border: 1px solid #17EDC3;
  }
}
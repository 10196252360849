.billing-wrapper {
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  padding: 48px;

  .title {
    margin-bottom: 32px;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: -0.5px;

    @media (max-width: 500px) {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }

  .billing-details-wrapper {
    display: flex;
    align-items: center;
    gap: 24px;

    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  .billing-details-card {
    width: calc(50% - 12px);
    height: 158px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.11) 100%), #090D23;
    border-radius: 10px;

    &:hover {
      .show-on-hover {
        display: flex;
      }
    }

    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  .billing-details-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 24px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.25px;
    }
  }

  .billing-details-content {
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 24px;

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 48px;
      min-height: 48px;
      background: rgba(23, 237, 195, 0.16);
      border-radius: 64px;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .show-on-hover {
    display: none;
  }

  .actions {
    display: none;
    align-items: center;
    gap: 12px;
    fill: white;
    fill-opacity: 0.56;

    .item-button:hover {
      cursor: pointer;

      path {
        fill: #17EDC3;
        fill-opacity: 1;
      }
    }
  }

  .card-expiration-date {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.17px;
    color: rgba(255, 255, 255, 0.7);
  }

  .table-wrapper {
    margin-top: 32px;
  }

  .payment-hisotry-header {
    padding: 16px 24px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.25px;
    }
  }

  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.11) 100%), #090D23;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    p {
      margin-top: 24px;
      font-size: 24px;
      font-weight: 500;
      line-height: 34px;
      letter-spacing: -0.5px;
    }
  }

  @media (max-width: 500px) {
    padding: 38px 16px;
  }
}
.deliverability-test-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;

  .title {
    margin-bottom: 10px;
  }

  .title-edit-mode {
    display: inline-flex;
    align-items: center;
    gap: 8px;

    .name-input {
      width: 360px;
  
      & > div {
       border: 1px solid #17EDC3;
      }
  
      input {
       padding-top: 8.5px;
       padding-bottom: 8.5px;
      }
  
      @media (max-width: 500px) {
       width: 250px;
      }
    }

    .check-icon-button {
      width: 40px;
      height: 40px;
      background-color: rgba(23, 237, 195, 0.12);
      border-radius: 4px;

      &:hover {
         background-color: rgba(23, 237, 195, 0.2);
      }

      &-disabled {
         path {
            fill: rgba(255, 255, 255, 0.3);
         }
      }
   }
  }

  &-name-part {
    display: flex;
    align-items: center;

    p {
      color: #FFFFFF;
      font-size: 32px;
      font-weight: 700;
      margin-right: 15px;
    }

    .edit-icon-container {
      cursor: pointer;
    }
  }

  &-email-part {
    display: flex;
    align-items: center;

    &-reload {
      width: 20px;
      height: 20px;
      background-color: #17EDC3;
      border-radius: 100%;
      box-sizing: border-box;
      padding: 1px 0 0 4px;
      margin-right: 5px;
    }

    &-text {
      font-size: 12;
      margin-right: 15px;
      color: #FFFFFF;
    }

    .MuiChip-root {
      padding: 6px;
      border: 0;
      height: 20px;

      span {
        font-size: 12px;
        font-weight: 500;
        padding: 0;
      }
    }
  }

  &-controls-part {
    display: flex;
    align-items: center;
  }

  .action-icon {
    margin-right: 10px;
  }
}

.send-your-email-modal-wrapper {
  width: 600px;
  padding-top: 36px;

  .email-list-input-wrapper {
    margin-bottom: 16px;

    p {
      font-size: 16px;
      margin-bottom: 16px;
    }
  }

  .confirm-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 40px;

    & > button {
       width: 76px;
       height: 40px;
    }
  }

  .list-controls {
    display: flex;
    gap: 16px;
    align-items: center;

    .copy-csv-button,
    .copy-to-clipboard-button {
      height: 40px;
      border-color: #FFFFFF8F;
      color: #FFFFFF;

      &:hover {
        background-color: initial;
        border-color: initial;
      }

      .start-icon {
        position: static;
        color: #FFFFFF;
        margin-right: 4px;
      }
    }

    .copy-csv-button {
      width: 173px;
    }

    .copy-to-clipboard-button {
      width: 220px;
    }

    .MuiInputBase-root {
      height: 40px;
      width: 218px;
      background-color: unset;
      border: 1px solid #FFFFFF8F;
      font-size: 14px;

      &:focus-within {
        background-color: unset;
        border-color: #FFFFFF8F;
      }

      &:hover {
        border-color: #FFFFFF;
        background: unset;
      }
    }

    .copy-as-select-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  .email-list-input > div:first-child {
    color: rgba(255, 255, 255, 0.50);
  }
}

.copy-to-clipboard-menu-wrapper {
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    align-items: center;
    width: 220px;
    gap: 16px;
    padding: 6px 24px;

    &:hover {
      cursor: pointer;
      background-color: rgb(27, 31, 50);
    }
  }
}

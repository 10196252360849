.email-template-mxrisk-wrapper {
   max-width: 1200px;
   padding: 48px;
   padding-bottom: calc(48px + 128px);

   .title {
      margin-bottom: 5px;

      .title-show-mode {
         display: flex;
         align-items: center;
         gap: 16px;

         p {
            max-width: 700px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-weight: 700;
            font-size: 32px;
            line-height: 44px;
            letter-spacing: -0.5px;

            @media (max-width: 900px) {
               font-weight: 500;
               font-size: 20px;
               line-height: 28px;
            }
         }
      }

      .title-edit-mode {
         display: inline-flex;
         gap: 8px;
      }
   }

   .breadcrumbs {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      line-height: 143%;
      letter-spacing: 0.17px;
      color: rgba(255, 255, 255, 0.5);

      p:first-child {
         color: #009688;
      }
   }

   .form-wrapper {
      margin-top: 33px;
      padding: 32px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #0A0E23;
      border-radius: 12px;

      .speedometer-container {
         margin-bottom: 16px;
         padding: 20px;
         background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), #081029;
         border-radius: 12px;

         display: flex;
         align-items: center;
         gap: 18px;

         p {
            max-width: 700px;
            color: rgba(255, 255, 255, 0.7);
            line-height: 24px;
            letter-spacing: 0.15px;

            @media (max-width: 900px) {
               font-size: 14px;
            }
         }
      }

      .template-format-wrapper {
         display: flex;
         align-items: center;
         gap: 24px;

         @media (max-width: 1200px) {
            flex-direction: column;
            align-items: start;

            & > div:first-child {
               width: 100%;
            }
         }
      }

      .select {
         width: 320px;
         height: 56px;

         @media (max-width: 1200px) {
            width: unset;
         }
      }

      .select-value {
         p:first-child {
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 0.15px;
            color: rgba(255, 255, 255, 0.7);
            margin-bottom: 3px;
         }
      }

      .merge-tag-wrapper {
         display: flex;
         align-items: center;
         gap: 10px;
         
         & > p {
            color: rgba(255, 255, 255, 0.7);
         }

         & > div {
            display: flex;
            align-items: center;
            gap: 10px;
         }

         @media (max-width: 900px) {
            flex-direction: column;
            align-items: start;
         }
      }

      .field-wrapper {
         display: flex;
         align-items: center;
         width: 210px;
         height: 48px;

         .first-merge-tag-input input,
         .second-merge-tag-input input {
            height: 46px;
            width: 30px;
            padding: 0;
         }


         .first-merge-tag-input input {
            padding-left: 15px;
            border-radius: 4px 0px 0px 4px;
         }

         .second-merge-tag-input input {
            padding-left: 15px;
            border-radius: 0px 4px 4px 0px;
         }
      }

      .merge-tag-input {
         width: 114px;
         height: 46px;
         background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #0A0E23;
         border: 1px solid rgba(0, 0, 0, 0.12);
         border-radius: 0px;

         & > div:first-child {
            background: none;
            border: 1px solid transparent;
         }

         input {
            padding: 12px;
         }
      }

      .email-form {
         display: flex;
         flex-direction: column;
         gap: 12px;
         margin: 12px 0;

         .input input {
            padding: 16px 12px;
          }
      }

      .mantine-RichTextEditor-root {
         border: 1px solid #141623;

         .mantine-RichTextEditor-toolbar,
         .mantine-RichTextEditor-content,
         .mantine-RichTextEditor-control {
            background-color: #141623;
         }

         .mantine-RichTextEditor-control[data-active] {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), #081029;
         }

         .mantine-RichTextEditor-toolbar {
            border-bottom: 1px solid rgba(255, 255, 255, 0.06);
            top: 0;
         }

         .mantine-RichTextEditor-control {
            color: rgba(255, 255, 255, 0.56);
            fill-opacity: 0.56;
            border: none;
         }

         .mantine-RichTextEditor-content {
            height: 275px;
            color: white;
            overflow-y: auto;

            &::-webkit-scrollbar-track {
               background-color: transparent;
             }

            &::-webkit-scrollbar {
               width: 6px;
               background-color: transparent;
             }
             
            &::-webkit-scrollbar-thumb {
               background: rgba(255, 255, 255, 0.6);
               border-radius: 4px;
             }
         }
      }

      .error {
         font-size: 12px;
         line-height: 20px;
         color: #F02B4F;
      }

      .text-editor-error {
         border: 1px solid #F02B4F;
         border-radius: 4px;
      }

      .body-error {
         margin-top: 3px;
         margin-left: 14px;
      }

      .next-button {
         margin-left: auto;
         width: 63px;
         height: 40px;
      }

      .emoji-wrapper {
         position: relative;
         width: 20px;
         height: 20px;
      }

      @media (max-width: 900px) {
         padding: 16px;
         margin-top: 16px;
      }
   }

   .loader {
      display: block;
      margin: 300px auto;
   }

   .edit-icon-container {
      cursor: pointer;
   }

   .name-input {
      width: 360px;

      & > div {
         border: 1px solid #17EDC3;
      }

      &-error > div {
         border: 1px solid #F02B4F;
      }

      input {
         padding-top: 8.5px;
         padding-bottom: 8.5px;
      }

      @media (max-width: 900px) {
         width: unset;
      }
   }

   .check-icon-button {
      width: 40px;
      height: 40px;
      background-color: rgba(23, 237, 195, 0.12);
      border-radius: 4px;

      &:hover {
         background-color: rgba(23, 237, 195, 0.2);
      }

      &-disabled {
         path {
            fill: rgba(255, 255, 255, 0.3);
         }
      }
   }

   .general-error {
      margin-bottom: 16px;
   }

   @media (max-width: 500px) {
      padding: 32px 16px;
   }
}

.mantine-Popover-dropdown,
.mantine-RichTextEditor-linkEditor input {
   background-color: #141623;
   border: 1px solid rgba(255, 255, 255, 0.06);

   .mantine-TextInput-rightSection button {
      background-color: #141623;
      border: 1px solid rgba(255, 255, 255, 0.56);
      color: white;
   }

   .mantine-RichTextEditor-linkEditorSave {
      background-color: #141623;
      border: 1px solid rgba(255, 255, 255, 0.06);
      color: rgba(255, 255, 255, 0.56);
   }
}